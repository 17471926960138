import React, { useEffect, useState } from "react";
import moment from "moment";
import PaginationControlled from "../../components/pagination/pagination";
import TemporaryDrawer from "../../components/Drawer/drawer";
import { useDispatch, useSelector } from "react-redux";
import AudioPlayer from "../bookDetails/audioPlayer";
import RenderPdfFile from "../../components/Drawer/renderPdfFile";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import MyBookCard from "./MybookCard";
export default function OrderTable() {
  const userReducer = JSON.parse(
    localStorage.getItem("persist:reducerUserStore")
  );
  const parseUser = JSON.parse(userReducer.userReducer);
  const userCredentials = parseUser.credentials;
  const [order, setorder] = useState([]);
  const [totalCount, settotalCount] = useState(0);

  const closeReader = useSelector((state) => state.closeReader);
  const bookShow = closeReader.closeReader;
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_URL}/order/user/book/${userCredentials._id}?page=1&size=10`
    ).then((data) => {
      data.json().then((result) => {
        if (result.success) {
          setorder(result.message);
        } else {
          setorder([]);
        }
      });
    });
    fetch(
      `${process.env.REACT_APP_URL}/order/get/count/order/book/${userCredentials._id}`
    ).then((response) =>
      response.json().then((data) => {
        if (data.success) {
          const newCount = Math.ceil(data.message / 10);
          settotalCount(newCount);
        } else {
          settotalCount(0);
        }
      })
    );
  }, []);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (pageNumber) => {
    fetch(
      `${process.env.REACT_APP_URL}/order/user/book/${userCredentials._id}?page=${pageNumber}&size=10`
    ).then((data) => {
      data.json().then((result) => {
        if (result.success) {
          setorder(result.message);
        } else {
          setorder([]);
        }
      });
    });
  };
  return (
    <div>
      <div></div>
      {order.length ? (
        <div className="row">
          {order.map((books) => (
            <MyBookCard books={books} />
          ))}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <PaginationControlled
              count={totalCount}
              handleChange1={handleChange}
            />
          </div>
        </div>
      ) : (
        "No record found"
      )}
    </div>
  );
}
