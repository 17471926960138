import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import BankTransfer from "./bankTransfer";
import "./paymentTab.css";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function LabTabs({ details }) {
  const classes = useStyles();
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <AppBar position="static">
          <TabList onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Bank Transfer" value="1" />
            {/* <Tab label="Airtime" value="2" /> */}
          </TabList>
        </AppBar>
        <TabPanel value="1">
          <BankTransfer details={{ ...details }} />
        </TabPanel>
        <TabPanel value="2">
          <div
            class="mobile-money-qr-payment"
            data-api-user-id="b12d7b22-3057-4c8e-ad50-63904171d18c"
            data-amount="100"
            data-currency="EUR"
            data-external-id="144-123-323"
          ></div>
        </TabPanel>
      </TabContext>
    </div>
  );
}
