import React, { useEffect, useState } from "react";
import Cards from "./cards";

export default function ChildrenHome() {
  const [books, setbooks] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/publish/best/seller/books`).then(
      (data) => {
        data.json().then((result) => {
          if (result.success) {
            setbooks(result.message);
          } else {
            setbooks([]);
          }
        });
      }
    );
  }, []);

  return (
   
    <div>
      <section class="section-margin">
        <div class="container">
          <div class="section-title section-title--bordered">
            <h2>Best Seller</h2>
          </div>
          <div
            class="product-list-slider slider-two-column product-slider multiple-row sb-slick-slider slider-border-multiple-row"
            data-slick-setting='{
                                            "autoplay": true,
                                            "autoplaySpeed": 8000,
                                            "slidesToShow":3,
                                            "rows":2,
                                            "dots":true
                                        }'
            data-slick-responsive='[
                                            {"breakpoint":1200, "settings": {"slidesToShow": 2} },
                                            {"breakpoint":992, "settings": {"slidesToShow": 2} },
                                            {"breakpoint":768, "settings": {"slidesToShow": 1} },
                                            {"breakpoint":575, "settings": {"slidesToShow": 1} },
                                            {"breakpoint":490, "settings": {"slidesToShow": 1} }
                                        ]'
          >
            <Cards details={{ ...books[0] }} />
            <Cards details={{ ...books[1] }} />
            <Cards details={{ ...books[2] }} />
            <Cards details={{ ...books[3] }} />
            <Cards details={{ ...books[4] }} />
            <Cards details={{ ...books[5] }} />
          </div>
        </div>
      </section>
    </div>
  );
}
