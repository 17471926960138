import React from "react";
import BasketAndView from "../basketAndView/basketAndView";

export default function ChildrenBooks({ details }) {
  return (
    <div class="single-slide">
      <div class="product-card">
        <div class="product-header">
          <a class="author">
            {" "}
            {details.author === undefined
              ? ""
              : details.author.length > 15
              ? "by " + details.author.substring(0, 15) + "..."
              : "by " + details.author}
          </a>
          <h3>
            <a href={"/details?productId=" + details._id}>
              {details.title === undefined
                ? ""
                : details.title.length > 15
                ? details.title.substring(0, 15) + "..."
                : details.title}
            </a>
          </h3>
        </div>
        <div class="product-card--body">
          <a href={"/details?productId=" + details._id}>
            <div
              class="card-image"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={details.image}
                alt=""
                style={{ height: "200px", width: "70%" }}
              />

              {details.title ? (
                <div class="hover-contents">
                  <BasketAndView productId={details._id} />
                </div>
              ) : (
                ""
              )}
            </div>
          </a>

          <div class="price-block">
            <span class="price">
              
              {Object.keys(details).length?details.price===0||details.price==="free"?"Free": "$" + details.price : ""}
            </span>
            {/* <del class="price-old">£51.20</del>
            <span class="price-discount">20%</span> */}
          </div>
        </div>
      </div>
    </div>
  );
}
