import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import MediaControlCard from "./audioCard";
import "./audiolist.css"
export default function MyBookList() {
  const querySearch = useLocation().search;
  const search = new URLSearchParams(querySearch).get("id");
  const [audios, setaudios] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/publish/audio/all/${search}`).then(
      (data) => {
        data.json().then((result) => {
          if (result.success) {
            setaudios(result.message);
          } else {
            setaudios([]);
          }
        });
      }
    );
  }, []);
  return (
    <div>
      <Header />
      <div>
        <section class="breadcrumb-section">
          <h2 class="sr-only">Site Breadcrumb</h2>
          <div class="container">
            <div class="breadcrumb-contents">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/my-account">My Account</a>
                  </li>
                  <li class="breadcrumb-item active">My Books</li>
                </ol>
              </nav>
            </div>
          </div>
        </section>
        <div
          className="container col-md-10 col-12"
          style={{ marginBottom: "100px" }}
        >
          <div className="row m-0">
            {audios.map((list, index) => (
              <div
                className="col-lg-3 col-md-4 col-12 audio-list-small"
                style={{ marginBottom: "20px" }}
                key={index}
              >
                {" "}
                <MediaControlCard audio={list} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
