import React from "react";
import { useDispatch } from "react-redux";

export default function Logout() {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch({ type: "user_details", data: null });
    window.location.href = "/login-register";
  };
  return (
    <a href="/login-register" onClick={handleLogout}>
      <i class="fas fa-sign-out-alt"></i> Logout
    </a>
  );
}
