import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AudioBook from "../../images/audiobook.png";
import AudioPlayer from "../bookDetails/audioPlayer";
import MyAudioPlayer from "./MyAudioPlayer";
const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard({ audio }) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <div class="card">
      <div class="card-body">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={AudioBook} alt="logo" />
        </div>
        <div style={{ width: "100%", marginTop: "20px", marginBottom: "10px" }}>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "700",
              marginRight: "5px",
            }}
          >
            {" "}
            Title:
          </span>
          {audio.title}
        </div>
        <div style={{ position: "absolute", left: "0px", width: "100%" }}>
          <MyAudioPlayer shortAudio={audio.file} />
        </div>
      </div>
    </div>
  );
}
