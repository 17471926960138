import React from "react";

export default function PromoSection() {
  return (
    <div>
      <section class="section-margin">
        <h2 class="sr-only">Promotion Section</h2>
        <div class="container">
          <div class="row space-db--30">
            <div class="col-lg-6 col-md-6 mb--30">
              <a href="#" class="promo-image promo-overlay">
                <img src="image/bg-images/promo-banner-with-text.jpg" alt="" />
              </a>
            </div>
            <div class="col-lg-6 col-md-6 mb--30">
              <a href="#" class="promo-image promo-overlay">
                <img
                  src="image/bg-images/promo-banner-with-text-2.jpg"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
