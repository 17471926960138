const InitState = {
  bookUrl: "",
};
const bookUrlReducer = (state = InitState, action) => {
  switch (action.type) {
    case "book_url":
      return {
        bookUrl: action.data,
      };

    default:
      return state;
  }
};
export default bookUrlReducer;
