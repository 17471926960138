import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Document } from "react-pdf/dist/esm/entry.webpack";
import { Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import RenderPdfFile from "./renderPdfFile";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function TemporaryDrawer({ bookUrl }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  console.log(state);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div style={{ height: "100vh", width: "100%" }}>
        <div>
          <CloseIcon
            style={{
              float: "right",
              marginTop: "10px",
              marginRight: "20px",
              cursor: "pointer",
              fontSize: "25px",
              fontWeight: "bolder",
              color: "#6019cf",
            }}
            onClick={toggleDrawer(anchor, false)}
          />
        </div>
        <div></div>
      </div>
    </div>
  );

  return (
    <div>
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <a
            style={{ cursor: "pointer" }}
            class="btn"
            onClick={toggleDrawer(anchor, true)}
          >
            Read
          </a>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
