import React, { useEffect } from "react";
import { EpubView } from "react-reader";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import RenderPdfFile from "../../components/Drawer/renderPdfFile";
import Epub from "./epubReader";
export default function Read() {
  const bookUrlReducer = useSelector((state) => state.bookUrlReducer);
  const getBookUrl = bookUrlReducer.bookUrl;
  const history = useHistory();
  // <RenderPdfFile />

  useEffect(() => {
    if (getBookUrl === "") {
      history.push("/my-account");
    }
  }, []);
  // <Epub />
  return <div>{getBookUrl ? <Epub /> : ""}</div>;
}
