import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard({ details }) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card>
      <CardContent>
        <Typography
          className={classes.title}
          style={{ color: "black" }}
          gutterBottom
          style={{ textAlign: "center" }}
        >
          {details.title}
        </Typography>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <img
            src={details.image}
            alt="image-logo"
            style={{ height: "200px" }}
          />
        </div>
      </CardContent>
      <CardActions>
        <div style={{ textAlign: "center", width: "100%" }}>
          You are buying for{" "}
          <span style={{ fontWeight: "700", color: "#6019CF" }}>
            {"$" + details.price}
          </span>
        </div>
      </CardActions>
    </Card>
  );
}
