import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function MyBookCard({ books }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const handleOpenPdf = (bookUrl, bookTitle) => {
    dispatch({ type: "book_url", data: { bookUrl, bookTitle } });
    history.push("/read");
  };
  const [audios, setaudios] = useState([]);
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_URL}/publish/audio/all/${books.bookId._id}`
    ).then((data) => {
      data.json().then((result) => {
        if (result.success) {
          setaudios(result.message);
        } else {
          setaudios([]);
        }
      });
    });
  }, []);
  return (
    <div className="col-md-4" style={{ marginBottom: "20px" }}>
      <Card>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={books.bookId ? books.bookId.image : ""}
            title={books.title}
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              Title:{" "}
              {books.bookId.title.length > 40
                ? books.bookId.title.substring(0, 40) + "..."
                : books.bookId.title}
            </Typography>
          </CardContent>
        </CardActionArea>

        <CardActions>
          {audios.length ? (
            <Button
              size="small"
              style={{ color: "#6019CF", fontWeight: "700" }}
              href={`/book-list?id=${books.bookId._id}`}
            >
              View Audios
            </Button>
          ) : (
            ""
          )}

          <Button
            onClick={() =>
              handleOpenPdf(books.bookId.bookFile, books.bookId.title)
            }
          >
            Read
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
