import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CategoryDropdown from "./categoryDropdown";

export default function Header() {
  const [category, setcategory] = useState([]);
  const userReducer = useSelector((state) => state.userReducer);
  const userCredentials = userReducer.credentials;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/category`).then((data) => {
      data.json().then((result) => {
        if (result.success) {
          setcategory(result.message);
        } else {
          setcategory([]);
        }
      });
    });
  }, []);

  return (
    <div>
      <div class="site-header d-none d-lg-block">
        <div class="header-middle pt--10 pb--10">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-3 ">
                <a href="/" class="site-brand">
                  <img
                    src="image/cawadi-logo.png"
                    alt=""
                    style={{ objectFit: "contain", width: "150px" }}
                  />
                  {/* <span
                    style={{
                      fontSize: "30px",
                      fontWeight: "700",
                      color: "#6019CF",
                    }}
                  >
                    {" "}
                    CAWADI
                  </span> */}
                </a>
              </div>
              {/* <div class="col-lg-3" >
                <div class="header-phone ">
                  <div class="icon">
                    <i class="fas fa-headphones-alt"></i>
                  </div>
                  <div class="text">
                    <p>Free Support 24/7</p>
                    <p class="font-weight-bold number">+01-202-555-0181</p>
                  </div>
                </div>
              </div> */}

              <div class="col-lg-9">
                <div class="main-navigation flex-lg-right">
                  <ul class="main-menu menu-right">
                    <li class="menu-item">
                      <a href="/">Home</a>
                    </li>
                   
                    {/* <li class="menu-item">
                      <a href="/shop">shop</a>
                    </li> */}
                    {userCredentials ? (
                      <li class="menu-item">
                        <a href="/my-account">My Account </a>
                      </li>
                    ) : (
                      <li class="menu-item">
                        <a href="/login-register">Login Register</a>
                      </li>
                    )}

                    <li class="menu-item">
                      <a href="/faq">FAQ</a>
                    </li>
                    <li class="menu-item">
                      <a href="/faq">Privacy Policy</a>
                    </li>
                    <li class="menu-item">
                      <a href="/terms">Terms of use</a>
                    </li>
                    <a
                       style={{color:"white",marginTop:"15px"}}
                       
                       href="https://publisher.cawadi.com/signin"
                      >
                    <li class="menu-item"   className="btn btn--primary" style={{background:"#6019CF",cursor:"pointer"}} >
                      
                        Publish
                     
                    </li>
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* category dropdown */}
        <CategoryDropdown category={category} />
      </div>

      <div class="site-mobile-menu">
        <header class="mobile-header d-block d-lg-none pt--10 pb-md--10">
          <div class="container">
            <div class="row align-items-sm-end align-items-center">
              <div class="col-md-4 col-7">
                <a href="/" class="site-brand">
                  <img
                    src="image/cawadi-logo.png"
                    alt=""
                    style={{ objectFit: "contain", width: "150px" }}
                  />
                </a>
              </div>
              <div class="col-md-5 order-3 order-md-2">
                <nav class="category-nav   ">
                  <div>
                    <a href="javascript:void(0)" class="category-trigger">
                      <i class="fa fa-bars"></i>Browse categories
                    </a>
                    <ul class="category-menu">
                      {category.map((list) => (
                        <li class="cat-item " key={list._id}>
                          <a href={"/category?" + list._id}>{list.name}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </nav>
              </div>
              <div class="col-md-3 col-5  order-md-3 text-right">
                <div class="mobile-header-btns header-top-widget">
                  <ul class="header-links">
                    {/* <li class="sin-link">
                      <a href="cart.html" class="cart-link link-icon">
                        <i class="ion-bag"></i>
                      </a>
                    </li> */}
                    <li class="sin-link">
                      <a
                        href="javascript:"
                        class="link-icon hamburgur-icon off-canvas-btn"
                      >
                        <i class="ion-navicon"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>

        <aside class="off-canvas-wrapper">
          <div class="btn-close-off-canvas">
            <i class="ion-android-close"></i>
          </div>
          <div class="off-canvas-inner">
            <div class="search-box offcanvas">
              <form>
                <input
                  type="text"
                  placeholder="Search Here"
                  style={{ display: "none" }}
                />
                <button class="search-btn">
                  <i class="ion-ios-search-strong"></i>
                </button>
              </form>
            </div>

            <div class="mobile-navigation">
              <nav class="off-canvas-nav">
                <ul class="mobile-menu main-mobile-menu">
                  <li class="">
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a
                      href="https://publisher.cawadi.com/signin"
                      style={{ color: "#6019CF", fontWeight: "800" }}
                    >
                      Publish book
                    </a>
                  </li>
                  {/* <li class="">
                    <a href="/shop">Shop</a>
                  </li> */}
                  {userCredentials ? (
                    <li class="">
                      <a href="/my-account">My Account</a>
                    </li>
                  ) : (
                    <li>
                      <a href="/login-register">Login Register</a>
                    </li>
                  )}

<li class="menu-item">
                      <a href="/faq">FAQ</a>
                    </li>
                    <li class="menu-item">
                      <a href="/faq">Privacy Policy</a>
                    </li>
                    <li class="menu-item">
                      <a href="/terms">Terms of use</a>
                    </li>
                 
                </ul>
              </nav>
            </div>

            {/* <nav class="off-canvas-nav">
              <ul class="mobile-menu menu-block-2">
                <li class="menu-item-has-children">
                  <a href="#">
                    Currency - USD $ <i class="fas fa-angle-down"></i>
                  </a>
                  <ul class="sub-menu">
                    <li>
                      {" "}
                      <a href="cart.html">USD $</a>
                    </li>
                    <li>
                      {" "}
                      <a href="checkout.html">EUR €</a>
                    </li>
                  </ul>
                </li>
                <li class="menu-item-has-children">
                  <a href="#">
                    Lang - Eng<i class="fas fa-angle-down"></i>
                  </a>
                  <ul class="sub-menu">
                    <li>Eng</li>
                    <li>Ban</li>
                  </ul>
                </li>
                <li class="menu-item-has-children">
                  <a href="#">
                    My Account <i class="fas fa-angle-down"></i>
                  </a>
                  <ul class="sub-menu">
                    <li>
                      <a href="#">My Account</a>
                    </li>
                    <li>
                      <a href="#">Order History</a>
                    </li>
                    <li>
                      <a href="#">Transactions</a>
                    </li>
                    <li>
                      <a href="#">Downloads</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav> */}
            <div class="off-canvas-bottom">
              <div class="contact-list mb--10">
                <a href="#" class="sin-contact">
                  <i class="fas fa-mobile-alt"></i>(12345) 78790220
                </a>
                <a href="#" class="sin-contact">
                  <i class="fas fa-envelope"></i>examle@handart.com
                </a>
              </div>
              <div class="off-canvas-social">
                <a href="#" class="single-icon">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a href="#" class="single-icon">
                  <i class="fab fa-twitter"></i>
                </a>
                <a href="#" class="single-icon">
                  <i class="fas fa-rss"></i>
                </a>
                <a href="#" class="single-icon">
                  <i class="fab fa-youtube"></i>
                </a>
                <a href="#" class="single-icon">
                  <i class="fab fa-google-plus-g"></i>
                </a>
                <a href="#" class="single-icon">
                  <i class="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </aside>
      </div>
      <div class="sticky-init fixed-header common-sticky">
        <div class="container d-none d-lg-block">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <a href="index.html" class="site-brand">
                <img
                  src="image/cawadi-logo.png"
                  alt=""
                  style={{ objectFit: "contain", width: "150px" }}
                />
                {/* <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "700",
                    color: "#6019CF",
                  }}
                >
                  {" "}
                  CAWADI
                </span> */}
              </a>
            </div>
            <div class="col-lg-9">
              <div class="main-navigation flex-lg-right">
                <ul class="main-menu menu-right ">
                  <li class="menu-item">
                    <a href="/">
                      Home <i class=""></i>
                    </a>
                  </li>

                  {/* <li class="menu-item has-children mega-menu">
                    <a href="/shop">
                      shop <i class=""></i>
                    </a>
                  </li> */}

                  {userCredentials ? (
                    <li class="menu-item ">
                      <a href="/my-account">My Account</a>
                    </li>
                  ) : (
                    <li class="menu-item">
                      <a href="/login-register">Login Register</a>
                    </li>
                  )}

<li class="menu-item">
                      <a href="/faq">FAQ</a>
                    </li>
                    <li class="menu-item">
                      <a href="/faq">Privacy Policy</a>
                    </li>
                    <li class="menu-item">
                      <a href="/terms">Terms of use</a>
                    </li>
                  <a
                       style={{color:"white",marginTop:"15px"}}
                       
                       href="https://publisher.cawadi.com/signin"
                      >
                    <li class="menu-item"   className="btn btn--primary" style={{background:"#6019CF",cursor:"pointer"}} >
                      
                        Publish
                     
                    </li>
                    </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
