const InitState = {
  randomNumber: Math.random() * 1000000000000,
};
const randomReducer = (state = InitState, action) => {
  switch (action.type) {
    case "random_details":
      return {
        credentials: action.data,
      };

    default:
      return state;
  }
};
export default randomReducer;
