import React from "react";
import SkelentonAnimations from "../skelenton/skelenton";

export default function Cards({ details }) {
 console.log({details})
  return (
    <div>
      <div class="single-slide">
        <div class="product-card card-style-list">
          <div class="card-image">
            <img src={details.image} alt="" style={{ height: "120px" }} />
          </div>
          <div class="product-card--body">
            <div class="product-header">
              <a href="#" class="author">
                {details.author ? "by " + details.author : ""}
              </a>
              <h3>
                <a href={"/details?productId=" + details._id}>
                  {details.title}
                </a>
              </h3>
            </div>
            <div class="price-block">
              <span class="price">
               
                {Object.keys( details).length ?details.price===0||details.price==="free"?"Free": "$" + details.price : ""}
              </span>
              {/* <del class="price-old">£51.20</del>
            <span class="price-discount">20%</span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
