import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TemporaryDrawer from "../../components/Drawer/drawer";
import SimpleAlerts from "../../components/messageAlert/Alert";
import SimpleRating from "../../components/rating/rating";
import ReadOnlyRating from "../../components/rating/readOnly";
import PositionedSnackbar from "../../components/snackBar/snackBar";
import AudioPlayer from "./audioPlayer";
import BookDescReader from "./bookDescReader";
import moment from "moment";
import AvgRating from "../../components/rating/avgRating";
import { useHistory } from "react-router-dom";
import SwapableDrawer from "./readerDrawer"
import Read from "../myAccount/read"
import SimpleCard from "../myAccount/audioCard"
export default function ProductDesc({ product }) {
  const userReducer = JSON.parse(
    localStorage.getItem("persist:reducerUserStore")
  );
  const history=useHistory();
  const parseUser = JSON.parse(userReducer.userReducer);
  const userCredentials = parseUser.credentials;
  const [message, setmessage] = useState({
    message: "",
    status: "",
  });
  const [showAudio, setshowAudio] = useState(false)

  const [audioUrl, setaudioUrl] = useState("");
  const handleBuy = () => {
    if (userCredentials === null) {
      setmessage({
        status: "error",
        message: "Please login to purchase this books.",
      });
    } 
  
    else {
      window.location.href = "/payment?product=" + product._id;
    }
  };
  const dispatch = useDispatch();
  const [averageRating, setaverageRating] = useState("");
  const [Comments, setComments] = useState([]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_URL}/publish/single/audio/${product._id}`
    ).then((data) => {
      data.json().then((result) => {
        if (result.success) {
          setaudioUrl(result.message);
        } else {
          setaudioUrl("");
        }
      });
    });
    fetch(
      `${process.env.REACT_APP_URL}/reviews/get/count/one/rating/${product._id}`
    ).then((data) => {
      data.json().then((result) => {
        if (result.success) {
          console.log(result);
          setaverageRating({ ...averageRating, rat1: result.message });
        }
      });
    });
    fetch(`${process.env.REACT_APP_URL}/reviews/comment/${product._id}`).then(
      (data) => {
        data.json().then((result) => {
          if (result.success) {
            setComments(result.message);
          } else {
            setComments([]);
          }
        });
      }
    );
  }, []);

  const handleRead = (title,bookFile) => {
   
      dispatch({ type: "book_url", data: { bookUrl:bookFile, bookTitle:title } });
      // window.location.href="/read"
      // history.push("/read");
    
  };
  const [commentParams, setcommentParams] = useState({
    comment: "",
    email: "",
    name: "",
    rating: 1,
  });
  const getRatingValue = (value) => {
    console.log(value);
    setcommentParams({
      ...commentParams,
      rating: value,
    });
  };
  const handleComment = (value, attr) => {
    setcommentParams({
      ...commentParams,
      [attr]: value,
    });
  };
  const [messageResult, setmessageResult] = useState({
    message: "",
    status: "",
    loader: false,
  });
  const handleSubmitReview = (e) => {
    setmessageResult({
      ...messageResult,
      loader: true,
    });
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: commentParams.name,
        comment: commentParams.comment,
        rating: commentParams.rating,
        bookId: product._id,
        email: commentParams.email,
      }),
    };
    fetch(`${process.env.REACT_APP_URL}/reviews/`, requestOptions).then(
      (data) => {
        data.json().then((result) => {
          if (result.success) {
            setmessageResult({
              message: result.message,
              loader: false,
              status: "success",
            });
            setcommentParams({
              comment: "",
              email: "",
              name: "",
              rating: 1,
            });
          } else {
            setmessageResult({
              message: result.message,
              loader: false,
              status: "error",
            });
            setcommentParams({
              comment: "",
              email: "",
              name: "",
              rating: 1,
            });
          }
        });
      }
    );
  };
const handleShowAudios=()=>{
  if(showAudio){
    setshowAudio(false)
  }else{
    setshowAudio(true)
  }
 
}
  return (
    <div>
      {product !== undefined ? (
        <div class="container">
          <div class="row  mb--60">
            <div class="col-lg-5 mb--30">
              <div
                class="product-details-slider sb-slick-slider arrow-type-two"
                data-slick-setting='{
                "slidesToShow": 1,
                "arrows": false,
                "fade": true,
                "draggable": false,
                "swipe": false,
                "asNavFor": ".product-slider-nav"
                }'
              >
                <div class="single-slide">
                  <img src={product.image} alt="" style={{ height: "400px" }} />
                </div>
                {/* <div class="single-slide">
                  <img src="image/products/product-details-2.jpg" alt="" />
                </div>
                <div class="single-slide">
                  <img src="image/products/product-details-3.jpg" alt="" />
                </div>
                <div class="single-slide">
                  <img src="image/products/product-details-4.jpg" alt="" />
                </div>
                <div class="single-slide">
                  <img src="image/products/product-details-5.jpg" alt="" />
                </div> */}
                {audioUrl ? (
                  <div style={{ marginTop: "20px" }}>
                    {
                      product.price === "" || product.price === 0?"":<AudioPlayer shortAudio={audioUrl} />
                    }
                    
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div
                class="mt--30 product-slider-nav sb-slick-slider arrow-type-two"
                data-slick-setting='{
              "infinite":true,
                "autoplay": true,
                "autoplaySpeed": 8000,
                "slidesToShow": 4,
                "arrows": true,
                "prevArrow":{"buttonClass": "slick-prev","iconClass":"fa fa-chevron-left"},
                "nextArrow":{"buttonClass": "slick-next","iconClass":"fa fa-chevron-right"},
                "asNavFor": ".product-details-slider",
                "focusOnSelect": true
                }'
              >
                {/* <div class="single-slide">
                  <img src="image/products/product-details-1.jpg" alt="" />
                </div> */}
                {/* <div class="single-slide">
                  <img src="image/products/product-details-2.jpg" alt="" />
                </div>
                <div class="single-slide">
                  <img src="image/products/product-details-3.jpg" alt="" />
                </div>
                <div class="single-slide">
                  <img src="image/products/product-details-4.jpg" alt="" />
                </div>
                <div class="single-slide">
                  <img src="image/products/product-details-5.jpg" alt="" />
                </div> */}
              </div>
            </div>
            <div class="col-lg-7">
              <div class="product-details-info pl-lg--30 ">
                <p class="tag-block">
                  Author: <a>{product.author}</a>
                </p>
                <h3 class="product-title">{product.title}</h3>
                {/* <ul class="list-unstyled">
                  <li>
                    Ex Tax: <span class="list-value"> £60.24</span>
                  </li>
                  <li>
                    Brands:{" "}
                    <a href="#" class="list-value font-weight-bold">
                      {" "}
                      Canon
                    </a>
                  </li>
                  <li>
                    Product Code: <span class="list-value"> model1</span>
                  </li>
                  <li>
                    Reward Points: <span class="list-value"> 200</span>
                  </li>
                  <li>
                    Availability: <span class="list-value"> In Stock</span>
                  </li>
                </ul> */}
                <div class="price-block">
                  <span class="price-new">
                   Price: {product.price === "" || product.price === 0
                      ? "Free"
                      : "$" + product.price}
                  </span><br></br>
                  <span class="">
                    Language: <strong>{product.language}</strong>
                  </span>
                  <br></br>
                  <span class="">
                   ISBN Number: <strong>{product.isbnNumber?product.isbnNumber:"Not Available."}</strong>
                  </span>
                  {/* <del class="price-old">£91.86</del> */}
                </div>
                
                ( Reviews)
                
                <AvgRating
                  averageRating={
                    averageRating.rat1 ? averageRating.rat1.avgRating : 0
                  }
                />
                <article class="product-details-article">
                  <h4 class="sr-only">Product Summery</h4>
                  <p>
                    {product
                      ? product.description.length > 500 ||
                        product.description.length !== undefined
                        ? <div dangerouslySetInnerHTML={{ __html:product.description.substring(0, 500) + "." }} /> 
                        :<div dangerouslySetInnerHTML={{ __html: product.description}} />
                      : ""}
                  </p>
                </article>
                <div class="add-to-cart-row">
                  
                  {/* <div class="count-input-block">
                    <span class="widget-label">Qty</span>
                    <input
                      type="number"
                      class="form-control text-center"
                      value="1"
                    />
                  </div> */}
                  {message ? <SimpleAlerts details={{ ...message }} /> : ""}
                  <div class="add-cart-btn">
                    {product.price === "" || product.price === 0 ? (
                     
                     <div> <button
                     class="btn btn-outlined--primary"
                     style={{ cursor: "pointer" }}
                     onClick={()=>handleRead(product.title,product.bookFile)}
                   >
                     <span class="plus-icon"></span>Read
                   </button>{
                     audioUrl.length?showAudio?<button
                     class="btn btn-outlined--primary"
                     style={{ cursor: "pointer" }}
                     onClick={handleShowAudios}
                     style={{marginLeft:"5px"}}
                   >
                     <span class="plus-icon"></span>Hide Audios
                   </button>:<button
                     class="btn btn-outlined--primary"
                     style={{ cursor: "pointer" }}
                     onClick={handleShowAudios}
                     style={{marginLeft:"5px"}}
                   >
                     <span class="plus-icon"></span>View Audios
                   </button>:""
                   }  </div>
                    ) : (
                      <a
                        class="btn btn-outlined--primary"
                        style={{ cursor: "pointer" }}
                        onClick={handleBuy}
                      >
                        <span class="plus-icon"></span>Buy
                      </a>
                    )}
                  </div>
                </div>
                {/* <div class="compare-wishlist-row">
                  <a href="#" class="add-link">
                    <i class="fas fa-heart"></i>Add to Wish List
                  </a>
                  <a href="#" class="add-link">
                    <i class="fas fa-random"></i>Add to Compare
                  </a>
                </div> */}
              </div>
            </div>
            {
              showAudio?<div style={{width:"100%",minHeight:"100%",maxHeight:"300px",overflowY:"scroll",paddingTop:"50px",paddingBottom:"70px",marginBottom:"100px"}}>
              <div style={{fontSize:"20px",color:"#6019cf",fontWeight:"700",marginBottom:"20px",paddingLeft:"30px"}}>Audio List</div>
              <div className="row m-0">
               {
                 audioUrl.length? audioUrl.map((audioList,index)=><div key={index} className="col-md-3 col-12" style={{marginBottom:"100px"}}>
                    <SimpleCard audio={audioList}/>
                 </div>):""
               }
              </div>
             
             
              
 
            </div>:""
            }
           
          
         
          </div>

          <div class="sb-custom-tab review-tab section-padding">
            <ul class="nav nav-tabs nav-style-2" id="myTab2" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab1"
                  data-toggle="tab"
                  href="#tab-1"
                  role="tab"
                  aria-controls="tab-1"
                  aria-selected="true"
                >
                  DESCRIPTION
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab2"
                  data-toggle="tab"
                  href="#tab-2"
                  role="tab"
                  aria-controls="tab-2"
                  aria-selected="true"
                >
                  REVIEWS ({averageRating.rat1 ? averageRating.rat1.total : 0})
                </a>
              </li>
            </ul>
            <div class="tab-content space-db--20" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="tab-1"
                role="tabpanel"
                aria-labelledby="tab1"
              >
                <article class="review-article">
                  <h1 class="sr-only">Tab Article</h1>
                  <p><div dangerouslySetInnerHTML={{ __html: product.description}} /></p>
                </article>
              </div>
              <div
                class="tab-pane fade"
                id="tab-2"
                role="tabpanel"
                aria-labelledby="tab2"
              >
                <div class="rating-block mb--15">
                  <span class="ion-android-star-outline star_on"></span>
                  <span class="ion-android-star-outline "></span>
                  <span class="ion-android-star-outline "></span>
                  <span class="ion-android-star-outline"></span>
                  <span class="ion-android-star-outline"></span>
                  Reviews({averageRating.rat1 ? averageRating.rat1.one : 0})
                </div>
                <div class="rating-block mb--15">
                  <span class="ion-android-star-outline star_on"></span>
                  <span class="ion-android-star-outline star_on"></span>
                  <span class="ion-android-star-outline "></span>
                  <span class="ion-android-star-outline"></span>
                  <span class="ion-android-star-outline"></span>
                  Reviews({averageRating.rat1 ? averageRating.rat1.two : 0})
                </div>
                <div class="rating-block mb--15">
                  <span class="ion-android-star-outline star_on"></span>
                  <span class="ion-android-star-outline star_on"></span>
                  <span class="ion-android-star-outline star_on"></span>
                  <span class="ion-android-star-outline"></span>
                  <span class="ion-android-star-outline"></span>
                  Reviews({averageRating.rat1 ? averageRating.rat1.three : 0})
                </div>
                <div class="rating-block mb--15">
                  <span class="ion-android-star-outline star_on"></span>
                  <span class="ion-android-star-outline star_on"></span>
                  <span class="ion-android-star-outline star_on"></span>
                  <span class="ion-android-star-outline star_on"></span>
                  <span class="ion-android-star-outline"></span>
                  Reviews({averageRating.rat1 ? averageRating.rat1.four : 0})
                </div>
                <div class="rating-block mb--15">
                  <span class="ion-android-star-outline star_on"></span>
                  <span class="ion-android-star-outline star_on"></span>
                  <span class="ion-android-star-outline star_on"></span>
                  <span class="ion-android-star-outline star_on"></span>
                  <span class="ion-android-star-outline star_on"></span>
                  Reviews({averageRating.rat1 ? averageRating.rat1.five : 0})
                </div>
                <div
                  style={{
                    marginBottom: "20px",
                    fontWeight: "700",
                    color: "#6019CF",
                  }}
                >
                  Average rating:
                  <AvgRating
                    averageRating={
                      averageRating.rat1 ? averageRating.rat1.avgRating : 0
                    }
                  />
                </div>
                <div class="review-wrapper">
                  {/* <h2 class="title-lg mb--20">
                    1 REVIEW FOR AUCTOR GRAVIDA ENIM
                  </h2> */}
                  {Comments.map((comment) => {
                    return (
                      <div class="review-comment mb--20">
                        <div class="avatar" >
                          <img src="image/dot-logo.png" alt="" style={{width:"40px",height:"40px"}}/>
                        </div>
                        <div class="text">
                          <ReadOnlyRating reviewValue={comment.rating} />
                          <h6 class="author">
                            {comment.name} –{" "}
                            <span class="font-weight-400">
                              {moment(comment.createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </span>
                          </h6>
                          <p>{comment.comment}</p>
                        </div>
                      </div>
                    );
                  })}

                  <h2 class="title-lg mb--20 pt--15">ADD A REVIEW</h2>
                  <div class="rating-row pt-2">
                    <p class="d-block">Your Rating</p>

                    <SimpleRating getRatingValue={getRatingValue} />

                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          {messageResult.message ? (
                            <SimpleAlerts details={{ ...messageResult }} />
                          ) : (
                            ""
                          )}
                          <label for="message">Comment</label>
                          <textarea
                            name="message"
                            id="message"
                            cols="30"
                            rows="3"
                            class="form-control"
                            onChange={(evt) =>
                              handleComment(evt.target.value, "comment")
                            }
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="name">Name *</label>
                          <input
                            type="text"
                            id="name"
                            class="form-control"
                            onChange={(evt) =>
                              handleComment(evt.target.value, "name")
                            }
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="email">Email *</label>
                          <input
                            type="text"
                            id="email"
                            class="form-control"
                            onChange={(evt) =>
                              handleComment(evt.target.value, "email")
                            }
                          />
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="submit-btn">
                          {messageResult.loader ? (
                            <a
                              class="btn btn-black"
                              style={{ color: "white", cursor: "pointer" }}
                            >
                              Please wait...
                            </a>
                          ) : (
                            <a
                              style={{ color: "white", cursor: "pointer" }}
                              class="btn btn-black"
                              onClick={handleSubmitReview}
                            >
                              Post Comment
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
