import React from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";

export default function Notfound() {
  return (
    <div>
      <Header />
      <div
        style={{
          paddingTop: "100px",
          paddingBottom: "100px",
          textAlign: "center",
          fontWeight: "700",
          fontSize: "20px",
        }}
      >
        Page not found
      </div>
      <Footer />
    </div>
  );
}
