import React from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useDispatch, useSelector } from "react-redux";
import MyBookIcon from "../../images/book.png";
import Logout from "../../pages/myAccount/logout";
export default function CategoryDropdown({ category }) {
  const userReducer = useSelector((state) => state.userReducer);
  const userCredentials = userReducer.credentials;

  return (
    <div>
      <div class="header-bottom pb--10">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <nav class="category-nav   ">
                <div>
                  <a href="javascript:void(0)" class="category-trigger">
                    <i class="fa fa-bars"></i>Browse categories
                  </a>
                  <ul class="category-menu">
                    {category.map((list) => (
                      <li class="cat-item " key={list._id}>
                        <a href={"/category?" + list._id}>{list.name}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>
            </div>
            <div class="col-lg-5">
            <div class="col-lg-12" >
                <div class="header-phone ">
                  <div class="icon">
                    <i class="fas fa-headphones-alt"></i>
                  </div>
                  <div class="text">
                    <p>Free Support 24/7</p>
                    <p class="font-weight-bold number">+01-202-555-0181</p>
                  </div>
                </div>
              </div>
              <div class="header-search-block" style={{ display: "none" }}>
                <input type="text" placeholder="Search entire store here" />
                <button>Search</button>
              </div>
            </div>
            
            <div class="col-lg-4">
              <div class="main-navigation flex-lg-right">
                <div class="cart-widget">
                  {userCredentials ? (
                    <div class="login-block">
                      <Logout />
                      <br />
                      <span>
                        <AccountCircleIcon style={{ color: "#6019CF" }} />
                      </span>
                      <a href="">
                        {userCredentials.email
                          ? userCredentials.email.length > 20
                            ? userCredentials.email.substring(0, 20) + "..."
                            : ""
                          : ""}
                      </a>
                    </div>
                  ) : (""
                    // <div class="login-block">
                    //   <a href="/login-register" class="font-weight-bold">
                    //     Login
                    //   </a>{" "}
                    //   <br />
                    //   <span>or</span>
                    //   <a href="/login-register">Register</a>
                    // </div>
                  )}

                  {/* <div class="">
                    <img
                      src={MyBookIcon}
                      alt="book-logo"
                      style={{ height: "24px" }}
                    />
                    <div class="cart-total">
                      <span class="text-item">My Books</span>
                      <span class="price">Store</span>
                    </div>
                    <div class="cart-dropdown-block">
                      <div class=" single-cart-block">
                        <div class="cart-product">
                          <a href="product-details.html" class="image">
                            <img src={MyBookIcon} alt="" />
                          </a>
                          <div class="content">
                            <h3 class="title">
                              <a href="product-details.html">
                                Kodak PIXPRO Astro Zoom AZ421 16 MP
                              </a>
                            </h3>
                            <p class="price">
                              <span class="qty">1 ×</span> £87.34
                            </p>
                            <button class="cross-btn">
                              <i class="fas fa-times"></i>
                            </button>
                          </div>
                        </div>
                      </div> */}
                  {/* <div class=" single-cart-block ">
                        <div class="btn-block">
                          <a href="cart.html" class="btn">
                            View Cart <i class="fas fa-chevron-right"></i>
                          </a>
                          <a href="checkout.html" class="btn btn--primary">
                            Check Out <i class="fas fa-chevron-right"></i>
                          </a>
                        </div>
                      </div> */}
                  {/* </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
