import { Button } from "@material-ui/core";
import { set } from "core-js/core/dict";
import React, { useState } from "react";
import { pdfjs } from "react-pdf";

import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { PDFReader } from "reactjs-pdf-reader";

import "./pdf.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function RenderPdfFile() {
  const bookUrlReducer = useSelector((state) => state.bookUrlReducer);
  const bookurl = bookUrlReducer.bookUrl.bookUrl;
  const bookSecUrl = bookurl.substring(0, 4) + "s" + bookurl.substring(4);
  // console.log(bookSecUrl);
  console.log(bookurl.bookUrl);
  const [getTotal, setgetTotal] = useState(0);
  const [selectedPage, setselectedPage] = useState(1);
  const getParams = useParams();
  // console.log(getParams.bookUrl);
  const [loader, setloader] = useState(false);
  const totalPage = (totalPage) => {
    var numbers = "sum";
    const numberArray = [];
    for (var i = 1; i <= totalPage; i++) {
      numberArray.push((numbers += i));
    }
    setgetTotal(numberArray);
    if (totalPage) {
      setloader(true);
    }
  };
  const selectPage =
    getTotal === 0
      ? ""
      : getTotal.map((data, index) => {
          return <option value={index + 1}>Page {index + 1}</option>;
        });
  console.log(selectPage);
  const handleChangePage = (value, attr) => {
    setselectedPage(parseInt(value));
  };
  console.log(loader);
  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          width: "100%",
          paddingLeft: "10px",
          paddingTop: "10px",
        }}
      >
        <ul style={{ display: "flex", width: "100%" }}>
          <span>
            <Link to="/my-account">
              {" "}
              <Button className="back__button">Close</Button>
            </Link>
          </span>
          <span>
            <select
              className="form-control select__button"
              style={{
                fontSize: "15px",
                fontWeight: "700",
                color: "#6019CF",
                cursor: "pointer",
              }}
              value={selectedPage}
              onChange={(evt) =>
                handleChangePage(evt.target.value, "changePage")
              }
            >
              {selectPage}
            </select>
          </span>
        </ul>
      </div>
      <PDFReader
        url={bookurl ? bookSecUrl : ""}
        onDocumentComplete={totalPage}
        page={selectedPage}
      />
      <div style={{ width: "100%", paddingLeft: "10px", paddingTop: "10px" }}>
        <ul style={{ display: "flex", width: "100%" }}>
          <span>
            <Link to="/my-account">
              {" "}
              <Button className="back__button">Close</Button>
            </Link>
          </span>
          <span>
            <select
              className="form-control select__button"
              style={{
                fontSize: "15px",
                fontWeight: "700",
                color: "#6019CF",
                cursor: "pointer",
              }}
              value={selectedPage}
              onChange={(evt) =>
                handleChangePage(evt.target.value, "changePage")
              }
            >
              {selectPage}
            </select>
          </span>
        </ul>
      </div>
      {/* <MobilePDFReader url={bookFile} scale={0.025} /> */}
    </div>
  );
}
