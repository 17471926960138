import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import SimpleAlerts from "../../components/messageAlert/Alert";

export default withRouter(function BankTransferForm({ history, details }) {
  console.log(details.price);
  const str = history.location.search;
  const bookId = str.substring(9);
  const userReducer = JSON.parse(
    localStorage.getItem("persist:reducerUserStore")
  );
  const parseUser = JSON.parse(userReducer.userReducer);
  const userCredentials = parseUser.credentials;
  const [orderDetails, setorderDetails] = useState({
    amountPaid: "",
    bookId: bookId,
    buyerId: userCredentials._id,
    transactionName: "",
    bankName: "",
    transactionDate: "",
    status: "pending",
    paymentType: "bank transfer",
  });
  const [messageDetails, setmessageDetails] = useState({
    message: "",
    status: "",
    loader: false,
  });
  const handleChange = (value, attr) => {
    setorderDetails({
      ...orderDetails,
      [attr]: value,
    });
  };

  const handleSubmitDetails = () => {
    setmessageDetails({
      ...messageDetails,
      loader: true,
    });

    if (String(details.price) !== orderDetails.amountPaid) {
      setmessageDetails({
        message:
          "The amount you enter does not correspond to the amount of books.",
        status: "error",
        loader: false,
      });
      setorderDetails({
        amountPaid: "",
        bookId: bookId,
        buyerId: userCredentials._id,
        transactionName: "",
        bankName: "",
        transactionDate: "",
        status: "pending",
        paymentType: "bank transfer",
      });
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amountPaid: orderDetails.amountPaid,
          bookId: bookId,
          buyerId: userCredentials._id,
          transactionName: orderDetails.transactionName,
          bankName: orderDetails.bankName,
          transactionDate: orderDetails.transactionDate,
          status: "pending",
          paymentType: orderDetails.paymentType,
          userId: details.userId,
        }),
      };
      fetch(`${process.env.REACT_APP_URL}/order/`, requestOptions).then(
        (res) => {
          res.json().then((result) => {
            if (result.success) {
              setmessageDetails({
                message: result.message,
                status: "success",
                loader: false,
              });
              setorderDetails({
                amountPaid: "",
                bookId: bookId,
                buyerId: userCredentials._id,
                transactionName: "",
                bankName: "",
                transactionDate: "",
                status: "pending",
                paymentType: "bank transfer",
              });
            } else {
              setmessageDetails({
                message: result.message,
                status: "error",
                loader: false,
              });
              setorderDetails({
                amountPaid: "",
                bookId: bookId,
                buyerId: userCredentials._id,
                transactionName: "",
                bankName: "",
                transactionDate: "",
                status: "pending",
                paymentType: "bank transfer",
              });
            }
          });
        }
      );
    }
  };
  return (
    <div>
      {messageDetails.message ? (
        <SimpleAlerts details={{ ...messageDetails }} />
      ) : (
        ""
      )}
      <div style={{ marginBottom: "20px" }}>
        <label>Amount Paid</label>
        <input
          className="form-control"
          type="text"
          onChange={(evt) => handleChange(evt.target.value, "amountPaid")}
          value={orderDetails.amountPaid}
        ></input>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <label>Name Used For transfer/Deposit</label>
        <input
          className="form-control"
          type="text"
          value={orderDetails.transactionName}
          onChange={(evt) => handleChange(evt.target.value, "transactionName")}
        ></input>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <label>Bank Name Used For transfer/Deposit</label>
        <input
          className="form-control"
          type="text"
          value={orderDetails.bankName}
          onChange={(evt) => handleChange(evt.target.value, "bankName")}
        ></input>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <label>transfer/Deposit date</label>
        <input
          className="form-control"
          type="text"
          value={orderDetails.transactionDate}
          onChange={(evt) => handleChange(evt.target.value, "transactionDate")}
        ></input>
      </div>
      {messageDetails.loader ? (
        <a
          class="btn btn-outlined--primary"
          style={{ cursor: "pointer", marginBottom: "20px" }}
        >
          Please wait...
        </a>
      ) : (
        <a
          class="btn btn-outlined--primary"
          style={{ cursor: "pointer", marginBottom: "20px" }}
          onClick={handleSubmitDetails}
        >
          Proceed
        </a>
      )}
    </div>
  );
});
