import logo from "./logo.svg";
import Header from "./components/header/header";
import Home from "./pages/home/home";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Footer from "./components/footer/footer";
import Shop from "./pages/shop/shop";
import BookDetails from "./pages/bookDetails/bookDetails";
import LoginRegister from "./pages/login-register/login-register";
import Payment from "./pages/payment/payment";
import myAccount from "./pages/myAccount/my-account";
import "./App.css";
import Notfound from "./pages/notFound/notfound";
import Read from "./pages/myAccount/read";
import MyBookList from "./pages/myAccount/MyBookList";
import Faq from "./pages/faq/faq";
import TearmsOfUse from "./pages/termsOfUse/tearmsOfUse";
function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <div class="site-wrapper" id="top">
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route path="/details" component={BookDetails}></Route>
            <Route path="/login-register" component={LoginRegister}></Route>
            <Route path="/payment" component={Payment}></Route>
            <Route path="/my-account" component={myAccount}></Route>

            <Route path="/category" component={Shop}></Route>
            <Route path="/read" component={Read}></Route>
            <Route path="/book-list" component={MyBookList}></Route>
            <Route path="/faq" component={Faq}></Route>
            <Route path="/terms" component={TearmsOfUse}></Route>

            <Route component={Notfound}></Route>
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
