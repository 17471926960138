import React from "react";

export default function Promotion3() {
  return (
    <section class="section-margin">
      <div class="promo-wrapper promo-type-three">
        <a
          href="#"
          class="promo-image promo-overlay bg-image"
          data-bg="image/bg-images/promo-banner-full.jpg"
        ></a>
        <div class="promo-text w-100 ml-0">
          <div class="container">
            <div class="row w-100">
              <div class="col-lg-7">
                <h2>I Love This Idea!</h2>
                <h3>Cover up front of book and leave summary</h3>
                <a href="#" class="btn btn--yellow">
                  $78.09 - Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
