import React from "react";

export default function Promotion2() {
  return (
    <div class="section-margin">
      <div class="container">
        <div class="row space-db--30">
          <div class="col-lg-8 mb--30">
            <div class="promo-wrapper promo-type-one">
              <a
                href="#"
                class="promo-image  promo-overlay bg-image"
                data-bg="image/bg-images/promo-banner-mid.jpg"
              ></a>
              <div class="promo-text">
                <div class="promo-text-inner">
                  <h2>Buy 3. Get Free 1.</h2>
                  <h3>50% off for selected products in Pustok.</h3>
                  <a href="#" class="btn btn-outlined--red-faded">
                    See More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mb--30">
            <div class="promo-wrapper promo-type-two ">
              <a
                href="#"
                class="promo-image promo-overlay bg-image"
                data-bg="image/bg-images/promo-banner-small.jpg"
              ></a>
              <div class="promo-text">
                <div class="promo-text-inner">
                  <span class="d-block price">$26.00</span>
                  <h2>
                    Praise for <br />
                    The Night Child
                  </h2>
                  <a href="#" class="btn btn-outlined--primary">
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
