import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import ProductDesc from "./productDesc";
import ProductSlider from "./reader";
import RelatedProduct from "./relatedProduct";
import HashLoader from "react-spinners/HashLoader";
import BookDescReader from "./bookDescReader";
import { useSelector } from "react-redux";
import Epub from "../myAccount/epubReader"
export default withRouter(function BookDetails({ history }) {
  //   const getParams = useParams();
  const bookUrlReducer = useSelector((state) => state.bookUrlReducer);
  const getBookUrl = bookUrlReducer.bookUrl;
  const str = history.location.search;
  const bookId = str.substring(11);
  const [product, setproduct] = useState("");
  const [loader, setloader] = useState(false);
  useEffect(() => {
    setloader(true);
    fetch(`${process.env.REACT_APP_URL}/publish/books/${bookId}`).then(
      (data) => {
        data.json().then((result) => {
          setloader(false);
          if (result.success) {
            setproduct(result.message);
          } else {
            setproduct("");
          }
        });
      }
    );
  }, []);
  const closeReader = useSelector((state) => state.closeReader);
  const bookShow = closeReader.closeReader;

  return (
    <div>
      {
        getBookUrl.bookUrl?<Epub/>:<div>
           {bookShow ? (
        <div style={{ paddingBottom: "50px", paddingTop: "20px" }}>
          <BookDescReader bookUrl={product.bookFile} />
        </div>
      ) : (
        <div>
          <Header />
          <section class="breadcrumb-section">
            <h2 class="sr-only">Site Breadcrumb</h2>
            <div class="container">
              <div class="breadcrumb-contents">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active">Product Details</li>
                  </ol>
                </nav>
              </div>
            </div>
          </section>

          <main class="inner-page-sec-padding-bottom">
            {product ? <ProductDesc product={{ ...product }} /> : ""}
            <RelatedProduct categoryId={product.category} />
          </main>

          <Footer />
        </div>
      )}

        </div>
      }
         </div>
  );
});
