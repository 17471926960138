import React from "react";

export default function HomeFeature() {
  return (
    <div>
      <section class="mb--30">
        <div class="container">
          <div class="row">
            <div class="col-xl-3 col-md-6 mt--30">
              <div class="feature-box h-100">
                <div class="icon">
                  <i class="fas fa-shipping-fast"></i>
                </div>
                <div class="text">
                  <h5>Free Shipping Item</h5>
                  <p> Orders over $500</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 mt--30">
              <div class="feature-box h-100">
                <div class="icon">
                  <i class="fas fa-redo-alt"></i>
                </div>
                <div class="text">
                  <h5>Money Back Guarantee</h5>
                  <p>100% money back</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 mt--30">
              <div class="feature-box h-100">
                <div class="icon">
                  <i class="fas fa-piggy-bank"></i>
                </div>
                <div class="text">
                  <h5>Cash On Delivery</h5>
                  <p>Lorem ipsum dolor amet</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 mt--30">
              <div class="feature-box h-100">
                <div class="icon">
                  <i class="fas fa-life-ring"></i>
                </div>
                <div class="text">
                  <h5>Help & Support</h5>
                  <p>Call us : + 0123.4567.89</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
