import React from 'react'
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'

export default function TearmsOfUse() {
    return (
        <div>
            <Header/>
            <div className="container" style={{marginTop:"20px",marginBottom:"100px"}}>
           <span style={{fontWeight:"700"}}> Terms of Use</span><br></br>
PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS WEBSITE. BY
USING THIS WEBSITE, YOU AGREE TO THESE TERMS OF USE. IF YOU DO NOT AGREE
<p>
TO THESE TERMS OF USE, PLEASE DO NOT USE THIS WEBSITE.
</p>
1. General.<br></br>
<p>
Welcome to Cawadi, an online bookstore that provides users with access to discover, read and
purchase literary works from content providers through multiple electronic interfaces including
Cawadi’s website, mobile applications, web applications or "widgets ", and other applications
(the "Service"). By registering with, accessing or using the Service you signify that you have
read, understand and agree to be bound by these terms of use (these "Terms of Use" or this
"Agreement"), whether or not you are a Customer of the Service. You are only authorized to use
the Service (regardless of whether your access or use is intended) if you agree to abide by all
applicable laws and with this Agreement. Cawadi reserves the right, at its sole discretion, to
change, modify, add, or delete portions of these Terms of Use at any time without further notice.
If cawadi does this, cawadi will post the changes to these terms and conditions on the Service.
Your continued use of the Service after any such changes constitutes your acceptance of the
revised terms and conditions.
If you do not comply with this Agreement at any time, cawadi reserves the right to immediately
cancel or terminate your access to the Service (or any part thereof) and/or your Customer
account, if any. In Cawadi’s sole discretion and without prior notice or liability, Cawadi may
discontinue, modify or alter any aspect of the Service including, but not limited to:
</p>
<ul>
    <li>Removing your work from our platform</li>
    <li>
    Suspending your account
    </li>
    <li>
    Banning you from our platform
    </li>
    </ul> 

<p>
You agree that any termination or cancellation of your access to, or use of, the Service may be
effected without prior notice. If you do not abide by the terms of this Agreement, you agree that
we may immediately deactivate or delete your Customer account, if any, and all related
information and/or files in your user account and/or bar any further access to such information
and/or files and/or the Service (or part thereof). Further, you agree that Cawadi shall not be
liable to you or any third-party for any termination or cancellation of your access to, or use of,
the Service. You acknowledge that your only right with respect to any dissatisfaction with any
modification or discontinuation of or to the Service, or any policies or practices by Cawadi in
providing the Service, including without limitation any change in content, is to cease using the
Service and cancel or terminate your subscription or Customer account, as applicable.
Digital Content.
</p>
<p>
All literary works on the Service (each, “Digital Content”) are the exclusive property of the
publisher or its licensors and are protected by copyright and other intellectual property laws. The
download of, and access to any Digital Content is available only to Customers and is intended
only for such Customers’ personal and non-commercial use. Any other use of Digital Content
downloaded or accessed from the Service is strictly prohibited. Customers may not modify,
transmit, publish, participate in the transfer or sale of, reproduce, create derivative works from,
distribute, perform, display, or in any way exploit, any of the content of any Digital Content, in
whole or in part. By downloading or otherwise accessing Digital Content from the Service, the
Customer hereby acknowledges and agrees to these terms.
Payment Methods.
</p>
Only registered users may purchase Digital Content on the Service.<br></br>
The Service accepts the following payment methods;<br></br>
<ul>
    <li>Debit/credit card</li>
    <li>Bitcoins</li>
    <li> Airtime</li>
</ul>
 

2.Customers.<br></br>
<p>
The Service is open to the public but certain portions, components, content and features of the
Service are only available to individuals who enter into a contract with Cawadi for the provision
of the Service by registering with Cawadi , creating a customer account on the Service, and
agreeing to these Terms of Use and Cawadi’s Privacy Policy at the time the Cawadi account is
created (each, a "Customer"). As long as the individual remains a Customer and their account is
active, Customers will have ongoing access to and will be able to continuously receive, amongst
others, the following additional services and functionality of the Service:
</p>
<ul>
    <li>the ability to access the Customer’s account and purchase Digital Content (including a
listing of Customer’s literary works) via one of Cawadi’s applications across multiple
platforms, including on the Customer’s devices such as eReaders, tablets, mobile
phones, and personal computers (“Devices”) and via Cawadi’s website;</li>
    <li>the ability to purchase, access, view, download, store, and read Digital Content on the
Customer’s Devices from within the Customer’s account while utilizing a Cawadi application;</li>
    <li>unlimited storage of Digital Content purchased or downloaded via the Customer’s account on
Cawadi’s systems, which can be accessed at all times when the Customer is logged into their
account and using a Cawadi application; and</li>
    <li>the ability to access certain functionality within Digital Content such as highlighting, in-book
dictionary, bookmarking, and annotations.</li>
   
    </ul>

If you are a Customer then you agree to the following:<br></br>
(a) provide true, accurate, current and complete information about yourself as prompted by any
registration form(s) on the Service, including your geographical location and billing address; and<br></br>
(b) maintain and promptly update the Registration Data to keep it true, accurate, current and
complete. If you provide any information that is untrue, inaccurate, not current or incomplete,
Cawadi has the right to suspend or terminate your account and refuse any and all current or
future use of the Service (or any portion thereof);<br></br>
(ii) Customers will receive a password and account designation upon completing the Service
registration process. You are responsible for maintaining the confidentiality of the password and
account and are fully responsible for all activities that occur under your password or account.<br></br>
You agree to:<br></br>

(a) immediately notify Cawadi of any unauthorized use of your password or account or any other
breach of security;
Any personal information you provide in the registration form will be collected in accordance
with, and for the express purposes set out in the Cawadi’s Privacy Policy.
Purchase of ISBN
Every publisher or writer will buy an ISBN before it book will be published on Cawadi. ISBN cost
$30 per one
Third Party Content.<br></br>
cawadi has no more editorial control over content than does a public library, bookstore, or
newsstand. Any opinions, advice, statements, services, offers, or other information or content
expressed or made available by third parties, including content providers and users, are those
of the respective author(s) or distributor(s) and not of Cawadi . In many instances, the content
available through the Service represents the opinions and judgments of the respective content
provider or user. Cawadi neither endorses nor is responsible for the accuracy or reliability of any
opinion, advice or statement made on the Service by anyone other than authorized Cawadi
employee spokespersons while acting in their official capacities.<br></br>
Indemnification.<br></br>
You agree to defend, indemnify and hold harmless Cawadi and its affiliates and their respective
directors, officers, employees and agents from and against any and all claims, actions,
demands, damages, costs, liabilities, losses and expenses (including reasonable attorneys'
fees) arising out of your use of the Service.<br></br>
Monitoring.<br></br>
Cawadi shall have the right, but not the obligation, to monitor the content of the Service
including any chat rooms and forums, to determine compliance with these Terms of Use and
any operating rules established by cawadi and to satisfy any law, regulation or authorized
government request. Cawadi shall have the right in its sole discretion to edit, refuse to post or
remove any material submitted to or posted on the Service. Without limiting the foregoing,
cawadi shall have the right to remove any material that cawadi in its sole discretion, finds to be
in violation of the provisions hereof, its CONTENT POLICY, or otherwise objectionable. Although
Cawadi reserves the right to remove, without notice, any posting for any reason, Cawadi has no
obligation to delete submissions that you may find objectionable or offensive.<br></br>
Restrictions on Use.<br></br>
These terms apply to all users of the Service, whether or not you are a Customer.<br></br>
A. You shall use the Service for lawful purposes only. You shall not post or transmit through the
Service any material that <br></br>(i) violates or infringes in any way upon the rights of others,<br></br> (ii) is
unlawful, threatening, abusive, defamatory, invasive of privacy or publicity rights, vulgar,
obscene, profane or otherwise objectionable, or <br></br>(iii) encourages conduct that would constitute a
criminal offense, gives rise to civil liability or otherwise violate any law. Without cawadi’s express
prior approval, you shall not post or transmit through the Service any material that contains
advertising or any solicitation with respect to products or services. You shall not use the Service
to advertise or perform any commercial solicitation, including, without limitation, the solicitation
of users to become subscribers of other on-line information services. Any conduct by you that in
Cawadi's discretion restricts or inhibits any other user from using or enjoying the Service will not
be permitted.<br></br>
B. All content on the Service, including but not limited to designs, text, graphics, pictures, video,
information, applications, software, music, sound and other files, and their selection and
arrangement ("Site Content"), are the proprietary property of Cawadi or its licensors with all
rights reserved. No Site Content may be modified, copied, distributed, framed, reproduced,
republished, downloaded, displayed, posted, transmitted, or sold in any form or by any means,
in whole or in part, without Cawadi's prior written permission. Provided that you are eligible for
use of the Service and subject to these Terms of Use, you are granted a limited license to
access the Service and the Site Content and to download or print a copy of any portion of the
Site Content to which you have properly gained access solely for your personal,
non-commercial use, provided that you keep all copyright and other proprietary notices intact.
You may not upload or republish Site Content on any Internet, Intranet or Extranet site or
incorporate the information in any other database or compilation, and any other use of the Site
Content is strictly prohibited. The foregoing license is subject to these Terms of Use and does
not include use of any data mining, robots or similar data gathering or extraction methods. Any
use of the Service or the Site Content other than as specifically authorized herein, without the
prior written permission of Cawadi, is strictly prohibited and will terminate the license granted
herein. Such unauthorized use may also violate applicable laws including without limitation
copyright and trade-mark laws and applicable communications regulations and statutes. Unless
explicitly stated herein, nothing in these terms and conditions shall be construed as conferring
any license to intellectual property rights, whether by estoppel, implication or otherwise. This
license is revocable by Cawadi at any time without notice and with or without cause.<br></br>
C. You shall not upload, post or otherwise make available on the Service any material protected
by copyright, trademark or other proprietary right without the express permission of the owner of
the copyright, trademark or other proprietary right and the burden of determining that any
material is not protected by copyright rests with you. You shall be solely liable for any damage
resulting from any infringement of copyrights, proprietary rights, or any other harm resulting from
such a submission. By submitting material to any public area of the Service, you automatically
grant, or warrant that the owner of such material has expressly granted Cawadi the royalty-free,
worldwide, perpetual, irrevocable, non-exclusive right and license to use, reproduce, modify,
adapt, publish, display, translate and distribute such material (in whole or in part) and/or to
incorporate it in other works in any form, media or technology now known or hereafter
developed. You also permit any other user of the Service to access, view, store or reproduce the
material for that user's personal use. You grant Cawadi the right to edit, copy, display, publish
and distribute any material made available on the Service by you. The foregoing does not apply
to literary works provided to Cawadi for sale on the Service by a publisher or other content
provider.<br></br>
D. You may not obscure or misrepresent your geographical location, forge headers, use proxies,
use IP spoofing or otherwise manipulate identifiers in order to disguise the origin of any
message or transmittal you send on or through the Service. You may not pretend that you are,
or that you represent, someone else, or impersonate any other individual or entity.<br></br>
E. You are prohibited from violating or attempting to violate the security of the Service, including,<br></br>
without limitation:<br></br>
(a) accessing data not intended for you, including logging into a server or account which you not
authorized to access;<br></br>
(b) attempting to probe, scan or test the vulnerability of a system or network to breach security
or authentication measures without proper authorization;<br></br>
(c) using any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program,
algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy
or monitor any portion of the Service or any Site Content, or in any way reproduce or circumvent
the navigational structure or presentation of the Service or any Site Content, to obtain or attempt
to obtain any materials, documents or information through any means not purposely made
available through the Site;<br></br>
(d) taking any action that imposes an unreasonable or disproportionately large load on the
infrastructure of the Service or Cawadi's systems or networks, or any systems or networks
connected to the Service or to Cawadi'<br></br>
(e) conducting a reverse look-up, tracing or seeking to trace any information on any other user,
Customer or visitor to the Service, or any other customer of Cawadi, to its source, or exploit the
Service or any service or information made available or offered by or through the Service, in any
way where the purpose is to reveal any information, including but not limited to personal
identification or information, other than your own information, as provided for by the Service;<br></br>
(f) attempting to interfere with, disrupt or disable service to any user, host or network, including,
without limitation, via means of "denial of service" attacks, overloading, "flooding",
"mailbombing" or "crashing";<br></br>
(g) forging any TCP/IP packet header or any part of the header information in any e-mail or
newsgroup posting;<br></br>
(h) disrupt network nodes or network services or otherwise restrict, inhibit, disrupt or impede
Cawadi's ability to monitor or make available the Service; or<br></br>
(i) taking any action in order to obtain services to which you are not entitled.
Violations of system or network security may result in civil or criminal liability. Cawadi will
investigate occurrences which may involve such violations and may involve, and cooperate with,
law enforcement authorities in prosecuting any user (whether or not a Customer) who is
involved in such violations.<br></br>
Disclaimer of Warranty; Limitation of Liability.<br></br>
A. YOU EXPRESSLY AGREE THAT USE OF THE WEBSITE IS AT YOUR SOLE RISK.
NEITHER CAWADI , ITS AFFILIATES NOR ANY OF THEIR RESPECTIVE EMPLOYEES,
AGENTS, THIRD PARTY CONTENT PROVIDERS OR LICENSORS WARRANT THAT THE
SITE WILL BE UNINTERRUPTED OR ERROR FREE; NOR DO THEY MAKE ANY
WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE
WEBSITE, OR AS TO THE ACCURACY OR RELIABILITY OF ANY INFORMATION, SERVICE
OR MERCHANDISE PROVIDED THROUGH THE WEBSITE.<br></br>
B. THE WEBSITE IS PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY
KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES
OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
PARTICULAR PURPOSE. ADDITIONALLY, THERE ARE NO WARRANTIES AS TO THE
RESULTS OBTAINED FROM THE USE OF THE WEBSITE.<br></br>
C. IN NO EVENT WILL CAWADI , OR ANY PERSON OR ENTITY INVOLVED IN CREATING,
PRODUCING OR DISTRIBUTING THE WEBSITE OR THE CONTENT INCLUDED THEREIN,
BE LIABLE IN CONTRACT, IN TORT (INCLUDING FOR ITS OWN NEGLIGENCE) OR UNDER
ANY OTHER LEGAL THEORY (INCLUDING STRICT LIABILITY) FOR ANY DAMAGES,
INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE,
CONSEQUENTIAL OR SIMILAR DAMAGES, INCLUDING, WITHOUT LIMITATION, LOST
PROFITS OR REVENUES, LOSS OF USE OR SIMILAR ECONOMIC LOSS, ARISING OUT
OF THE USE OF OR INABILITY TO USE THE WEBSITE. YOU HEREBY ACKNOWLEDGE
THAT THE PROVISIONS OF THIS SECTION SHALL APPLY TO ALL USE OF AND CONTENT
ON THE WEBSITE. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION
OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT SHALL CAWADI’S
TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION
WHETHER IN CONTRACT, TORT (INCLUDING ITS OWN NEGLIGENCE) OR UNDER ANY
OTHER LEGAL THEORY (INCLUDING STRICT LIABILITY) EXCEED THE AMOUNT PAID BY
YOU, IF ANY, FOR ACCESSING THIS WEBSITE.<br></br>
D. THE ABOVE DISCLAIMER OF LIABILITY APPLIES TO ANY DAMAGES OR INJURY
CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INACCURACY,
INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION,
COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR
UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF THIS WEBSITE, WHETHER
FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR (INCLUDING STRICT LIABILITY),
NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION. YOU SPECIFICALLY
ACKNOWLEDGE THAT CAWADI IS NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE OR
ILLEGAL CONDUCT OF OTHER USERS OR THIRD-PARTIES AND THAT THE RISK OF
INJURY FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
            </div>
            <Footer/>
        </div>
    )
}
