import React from "react";

export default function BasketAndView({ productId }) {
  return (
    <div>
      <div class="hover-btns">
        {/* <a href="cart.html" class="single-btn">
          <i class="fas fa-shopping-basket"></i>
        </a> */}
        {/* <a href="wishlist.html" class="single-btn">
                    <i class="fas fa-heart"></i>
                  </a> */}
        {/* <a href="compare.html" class="single-btn">
                    <i class="fas fa-random"></i>
                  </a> */}
        <a href={"/details?productId=" + productId} class="single-btn">
          <i class="fas fa-eye"></i>
        </a>
      </div>
    </div>
  );
}
