import React from 'react'
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import Faq from "react-faq-component";
export default function FaqPage() {
    const data = {
        title: "FAQ (How it works)",
        rows: [
            {
                title: "How to publish on CAWADI?",
                content: `Publishing on cawadi is not a rocket science, it’s as simple as having a cup of tea in the morning.
                You need to upload your work as an ePub format on your cawadi account after you have
                purchase an ISBN for the book you want to publish.
                How do I get my book or stories published on cawadi?
                You simply need to upload the epub version of your book or short story and a cover-page (in
                JPG).
                And your work will be instantly added to our library.`,
            },
            {
                title: "WHAT KIND OF FILES CAN I UPLOAD?",
                content:
                    `We accept epub, but if your book isn't in this format, you don’t need to worry, your can do that
                    via this link <a href='https://ebook.online-convert.com/convert-to-epub'> https://ebook.online-convert.com/convert-to-epub</a>`,
            },
            {
                title: "DO I HAVE TO PAY TO PUBLISH?",
                content: ` You never have to pay to publish on Cawadi. All you need is a killer story, your content and a registered account.`,
            },
            {
                title: "DO I need an International Standard Book Number (ISBN) to be published?",
                content: `Yes, you need an ISBN that will be used to track your books and it will be provided to you at a
                friendly price.`,
            },
            {
                title: "HOW DO I GET PAID?",
                content: `Getting paid after selling your books is easy and quick. Simply fill out a payout form letting us
                know the amount needed, and we credit your bank account.`,
            },
            {
                title: "CAN I EDIT MY BOOK AFTER PUBLISHING?",
                content: `
                Certainly! You simply have to go into your publisher dashboard, select the book to edit and make
                changes.`,
            },
            {
                title: "So how do I pay for books?",
                content: `
                Cawadi has three payment method for the comfort of our users, we accept credit/debit card
                payments from all over the world, we accept payment with airtime, and you can also make
                payment with bitcoin.`,
            },
            {
                title: "I have short stories but I am not a published author, can I still get published on cawadi?",
                content: `
                Cawadi is not just about books and neither are we just about published authors. Our platform is
                for everyone to be able to tell their story and share the experience. it is an avenue for young and
                established authors to get their works distributed and monetized as early as possible.`,
            },
            {
                title: "Where will my books be sold?",
                content: `
                CAWADI is a global platform and your books will be available globally, for your fans to access.`,
            },
            {
                title: "What Counts As Prohibited Content On cawadi?",
                content: `
                We love freedom of expression cawadi, and are focused on content that inspires, equips, and
                motivate people to be and do better. Therefore, any form of hate speech and information that
                denigrates, and dose not exhort will not be permitted on the platform. Here is some of the
                prohibited content:
                Pornography, Nudity, and Sexually Explicit Material: Adult or explicit material depicting illegal
                acts or deemed to be exploitative shall be considered pornography and blocked or removed from
                our Website.
                If your content contains appropriate adult or explicit material, please mark the page as containing
                mature content as a courtesy to other Users, so that they will have the choice whether or not to
                view this content. You can do this either in the subtitle or in the opening paragraph of your book
                description. We reserve the right to put such content containing mature content, behind an
                interstitial.
                Hateful content: Users may not publish material that promotes hate toward groups based on race
                or ethnic origin, religion, disability, gender, age, veteran status, and sexual orientation/gender
                identity.
                Violent content: Users may not publish direct threats of violence against any person or group of
                people. We also ask Users not to publish content that contains graphic violence. Users may not
                publish instructional material regarding the creation of weapons of mass destruction.
                Pirated content: Don’t post anything you didn’t write on Cawadi without the explicit permission
                of the person who did write it. Don’t upload pirated versions of published works, or any other
                content that belongs to someone else. Posting plagiarised and pirated works of any kind is illegal
                and punishable by law.
                Failure to adhere to our strict policies will lead to suspension of account and persistent violation
                will lead to account permanently disabled. If you are unsure of whether your content is in
                violation of rules above, feel free to send us an email`,
            },
            {
                title: "Do You Protect Authors Against Piracy?",
                content: `Cawadi hates pirates as much as you do, they steal ships and speak funny. So our team of smart
                developers have created DRM versions of epubs that ensures that your books are not illegally
                distributed.<br/>However, if you feel that your book has been illegally uploaded to Cawadi and would like to
                remove the book from our library and you own the rights to it ....then send an email with proof
                and we will process your request to remove the book in question. `,
            },
            {
                title: "How Much Royalty Do We Get?",
                content: `Authors make 70% on each sale.`,
            },
        ],
    };
    
    const styles = {
        // bgColor: 'white',
        titleTextColor: "#6019CF",
        rowTitleColor: "#6019CF",
       
        // rowContentColor: 'grey',
        // arrowColor: "red",
    };
    
    const config = {
        animate: true,
        // arrowIcon: "V",
        // tabFocus: true
    };
    
    return (
        <div>
            <Header/>
           <div className="container" style={{marginTop:"20px",marginBottom:"50px"}}>
           <Faq
                data={data}
                styles={styles}
                config={config}
            />
           </div>
          <Footer/>
        </div>
    )
}
