import React from "react";
import RenderPdfFile from "../../components/Drawer/renderPdfFile";

export default function BookDescReader({ bookUrl }) {
  return (
    <div>
      <RenderPdfFile bookFile={bookUrl} />
    </div>
  );
}
