const InitState = {
  closeReader: false,
};
const closeReaderReducer = (state = InitState, action) => {
  switch (action.type) {
    case "close_reader":
      return {
        closeReader: action.data,
      };

    default:
      return state;
  }
};
export default closeReaderReducer;
