import ReactAudioPlayer from "react-audio-player";
//...
import React from "react";

export default function AudioPlayer({ shortAudio }) {
  return (
    <div>
      <ReactAudioPlayer src={shortAudio} autoPlay={false} controls />
    </div>
  );
}
