import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RenderPdfFile from "../../components/Drawer/renderPdfFile";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import EditAccount from "./editAccount";
import Logout from "./logout";
import MyBooksTable from "./myBooksTable";
import OrderTable from "./orderTable";
import UserAddress from "./userAddress";

export default function myAccount() {
  const userReducer = JSON.parse(
    localStorage.getItem("persist:reducerUserStore")
  );

  const parseUser = JSON.parse(userReducer.userReducer);
  const userCredentials = parseUser.credentials;

  return (
    <div>
      {/* <RenderPdfFile /> */}
      <Header />
      <section class="breadcrumb-section">
        <h2 class="sr-only">Site Breadcrumb</h2>
        <div class="container">
          <div class="breadcrumb-contents">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li class="breadcrumb-item active">My Account</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <div class="page-section inner-page-sec-padding">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-3 col-12">
                  <div class="myaccount-tab-menu nav" role="tablist">
                    <a href="#dashboad" class="active" data-toggle="tab">
                      <i class="fas fa-tachometer-alt"></i>
                      Dashboard
                    </a>
                    <a href="#orders" data-toggle="tab">
                      <i class="fa fa-cart-arrow-down"></i> Orders
                    </a>
                    <a href="#download" data-toggle="tab">
                      <i class="fas fa-download"></i> My Books
                    </a>
                    {/* <a href="#payment-method" data-toggle="tab">
                      <i class="fa fa-credit-card"></i>
                      Payment Method
                    </a> */}
                    <a href="#address-edit" data-toggle="tab">
                      <i class="fa fa-map-marker"></i>
                      address
                    </a>
                    <a href="#account-info" data-toggle="tab">
                      <i class="fa fa-user"></i> Account Details
                    </a>
                    <Logout />
                  </div>
                </div>

                <div class="col-lg-9 col-12 mt--30 mt-lg--0">
                  <div class="tab-content" id="myaccountContent">
                    <div
                      class="tab-pane fade show active"
                      id="dashboad"
                      role="tabpanel"
                    >
                      <div class="myaccount-content">
                        <h3>Dashboard</h3>
                        <div class="welcome mb-20">
                          <p>
                            Hello, <strong>{userCredentials.fullName}</strong>{" "}
                          </p>
                        </div>
                        <p class="mb-0">
                          From your account dashboard. you can easily check
                          &amp; view your recent orders, manage and edit your
                          password and account details.
                        </p>
                      </div>
                    </div>

                    <div class="tab-pane fade" id="orders" role="tabpanel">
                      <div class="myaccount-content">
                        <h3>Orders</h3>
                        <OrderTable />
                      </div>
                    </div>

                    <div class="tab-pane fade" id="download" role="tabpanel">
                      <div class="myaccount-content">
                        <h3>My Books</h3>
                        <MyBooksTable />
                      </div>
                    </div>

                    {/* <div
                      class="tab-pane fade"
                      id="payment-method"
                      role="tabpanel"
                    >
                      <div class="myaccount-content">
                        <h3>Payment Method</h3>
                        <p class="saved-message">
                          You Can't Saved Your Payment Method yet.
                        </p>
                      </div>
                    </div> */}

                    <div
                      class="tab-pane fade"
                      id="address-edit"
                      role="tabpanel"
                    >
                      <div class="myaccount-content">
                        <h3>Address</h3>
                        <UserAddress />
                        {/* <a href="#" class="btn btn--primary">
                          <i class="fa fa-edit"></i>Edit Address
                        </a> */}
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="account-info"
                      role="tabpanel"
                    >
                      <div class="myaccount-content">
                        <h3>Account Details</h3>
                        <EditAccount />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
