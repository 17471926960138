import React, { useEffect, useState } from "react";
import SimpleAlerts from "../../components/messageAlert/Alert";

export default function EditAccount() {
  const userReducer = JSON.parse(
    localStorage.getItem("persist:reducerUserStore")
  );
  const parseUser = JSON.parse(userReducer.userReducer);
  const userCredentials = parseUser.credentials;
  const [userProfile, setuserProfile] = useState({
    fullName: "",
    displayName: "",
    email: "",
    phoneNumber: "",

    address: "",
    password: "",
  });
  const handleChange = (value, attr) => {
    setuserProfile({
      ...userProfile,
      [attr]: value,
    });
  };
  const [messageDetails, setmessageDetails] = useState({
    message: "",
    status: "",
    loader: false,
    loader1: false,
    message1: "",
  });
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_URL}/user/profile/user/${userCredentials._id}`
    ).then((data) => {
      data.json().then((result) => {
        if (result.success) {
          setuserProfile({
            fullName: result.message.fullName ? result.message.fullName : "",
            displayName: result.message.displayName
              ? result.message.displayName
              : "",
            email: result.message.email ? result.message.email : "",
            address: result.message.address ? result.message.address : "",
            phoneNumber: result.message.phoneNumber
              ? result.message.phoneNumber
              : "",
            password: result.message.passwordHash
              ? result.message.passwordHash
              : "",
          });
        } else {
          setuserProfile(result.message);
        }
      });
    });
  }, []);
  const handleUpdateDetails = () => {
    setmessageDetails({
      message: "",
      status: "",
      loader: true,
    });
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName: userProfile.fullName,
        displayName: userProfile.displayName,
        email: userProfile.email,
        phoneNumber: userProfile.phoneNumber,

        address: userProfile.address,
        password: userProfile.password,
      }),
    };
    fetch(
      `${process.env.REACT_APP_URL}/user/profile/update/${userCredentials._id}`,
      requestOptions
    ).then((res) => {
      res.json().then((result) => {
        if (result.success) {
          setuserProfile({
            fullName: result.message.fullName,
            displayName: result.message.displayName,
            email: result.message.email,
            phoneNumber: result.message.phoneNumber,

            address: result.message.address,
            password: result.message.password,
          });
          setmessageDetails({
            message:
              "Profile updated successful,Please logout to reflect changes.",
            status: "success",
            loader: false,
          });
        } else {
          setmessageDetails({
            message: result.message,
            status: "error",
            loader: false,
          });
        }
      });
    });
  };
  const [passwordChage, setpasswordChage] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const handlePasswordChange = (value, attr) => {
    setpasswordChage({
      ...passwordChage,
      [attr]: value,
    });
  };
  const handlePasswordUpdateButton = () => {
    setmessageDetails({
      ...messageDetails,
      loader1: true,
    });
    if (passwordChage.newPassword !== passwordChage.confirmPassword) {
      setmessageDetails({
        loader1: false,
        message1: "The password you enter does not match.",
        status: "error",
        message: "",
      });
      setpasswordChage({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } else {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          oldPassword: passwordChage.oldPassword,
          password: passwordChage.newPassword,
        }),
      };
      fetch(
        `${process.env.REACT_APP_URL}/user/profile/update/password/${userCredentials._id}`,
        requestOptions
      ).then((data) => {
        data.json().then((result) => {
          if (result.success) {
            setmessageDetails({
              loader1: false,
              message1: result.message,
              status: "success",
              message: "",
            });
            setpasswordChage({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
          } else {
            setmessageDetails({
              loader1: false,
              message1: result.message,
              status: "error",
              message: "",
            });
            setpasswordChage({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
          }
        });
      });
    }
  };
  return (
    <div>
      <div class="account-details-form">
        <div class="row">
          {messageDetails.message ? (
            <SimpleAlerts details={{ ...messageDetails }} />
          ) : (
            ""
          )}
          <div class="col-lg-6 col-12  mb--30">
            <input
              id="first-name"
              placeholder="Full Name"
              type="text"
              value={userProfile.fullName}
              onChange={(evt) => handleChange(evt.target.value, "fullName")}
            />
          </div>
          <div class="col-lg-6 col-12  mb--30">
            <input
              id="last-name"
              placeholder="Display Name"
              type="text"
              value={userProfile.displayName}
              onChange={(evt) => handleChange(evt.target.value, "displayName")}
            />
          </div>
          {/* <div class="col-12  mb--30">
            <input
              id="display-name"
              placeholder="Email"
              type="email"
              value={userProfile.email}
              onChange={(evt) => handleChange(evt.target.value, "email")}
            />
          </div> */}
          <div class="col-12  mb--30">
            <input
              id="email"
              placeholder="Address"
              type="text"
              value={userProfile.address}
              onChange={(evt) => handleChange(evt.target.value, "address")}
            />
          </div>
          <div class="col-12  mb--30">
            <input
              id="email"
              placeholder="Phone Number"
              type="text"
              value={userProfile.phoneNumber}
              onChange={(evt) => handleChange(evt.target.value, "phoneNumber")}
            />
          </div>
          <div class="col-12" style={{ marginBottom: "20px" }}>
            {messageDetails.loader ? (
              <button class="btn btn--primary">Please wait...</button>
            ) : (
              <button class="btn btn--primary" onClick={handleUpdateDetails}>
                Save Changes
              </button>
            )}
          </div>
          <div class="col-12  mb--30">
            <h4>Password change</h4>
          </div>
          <div class="col-12  mb--30">
            {messageDetails.message1 ? (
              <SimpleAlerts details={{ ...messageDetails }} />
            ) : (
              ""
            )}
            <input
              id="current-pwd"
              placeholder="Current Password"
              type="password"
              onChange={(evt) =>
                handlePasswordChange(evt.target.value, "oldPassword")
              }
            />
          </div>
          <div class="col-lg-6 col-12  mb--30">
            <input
              id="new-pwd"
              placeholder="New Password"
              type="password"
              onChange={(evt) =>
                handlePasswordChange(evt.target.value, "newPassword")
              }
            />
          </div>
          <div class="col-lg-6 col-12  mb--30">
            <input
              id="confirm-pwd"
              placeholder="Confirm Password"
              type="password"
              onChange={(evt) =>
                handlePasswordChange(evt.target.value, "confirmPassword")
              }
            />
          </div>
          <div class="col-12">
            {messageDetails.loader1 ? (
              <button class="btn btn--primary">Please wait...</button>
            ) : (
              <button
                class="btn btn--primary"
                onClick={handlePasswordUpdateButton}
              >
                Save Changes
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
