import React, { useEffect, useState } from "react";
import NewArrivalSlide from "./newArrivalSlide";

export default function NewArrival() {
  const [allBooks, setallBooks] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/publish/all/newArrival/books`).then(
      (data) => {
        data.json().then((result) => {
          if (result.success) {
            setallBooks(result.message);
          } else {
            setallBooks([]);
          }
        });
      }
    );
  }, []);
  return (
    <div>
      <section
        class="section-margin bg-image section-padding-top section-padding"
        data-bg="image/bg-images/best-seller-bg.jpg"
      >
        <div class="container">
          <div class="section-title section-title--bordered mb-0">
            <h2>New Arrival</h2>
          </div>
          <div class="best-seller-block">
            <div class="row align-items-center">
              <div class="col-lg-5 col-md-6">
                {
                  allBooks[5]? <a href={"/details?productId=" + allBooks[5]._id}>
                  <div class="author-image">
                 
                    <img src={allBooks[5]?allBooks[5].image:"Product image"} alt="image" style={{height:"400px",width:"100%"}}/>
                   
                  </div>
                  </a>:""
                }
             
              </div>
              <div class="col-lg-7 col-md-6">
                <div
                  class="sb-slick-slider product-slider product-list-slider multiple-row slider-border-multiple-row"
                  data-slick-setting='{
                                    "autoplay": false,
                                    "autoplaySpeed": 8000,
                                    "slidesToShow":2,
                                    "rows":2,
                                    "dots":true
                                }'
                  data-slick-responsive='[
                                    {"breakpoint":1200, "settings": {"slidesToShow": 1} },
                                    {"breakpoint":992, "settings": {"slidesToShow": 1} },
                                    {"breakpoint":768, "settings": {"slidesToShow": 1} },
                                    {"breakpoint":575, "settings": {"slidesToShow": 1} },
                                    {"breakpoint":490, "settings": {"slidesToShow": 1} }
                                ]'
                >
                  <NewArrivalSlide newArrival={{ ...allBooks[0] }} />
                  <NewArrivalSlide newArrival={{ ...allBooks[1] }} />
                  <NewArrivalSlide newArrival={{ ...allBooks[2] }} />
                  <NewArrivalSlide newArrival={{ ...allBooks[3] }} />
                  <NewArrivalSlide newArrival={{ ...allBooks[4] }} />
                  <NewArrivalSlide newArrival={{ ...allBooks[5] }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
