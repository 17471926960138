import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import PaginationStore from "../../components/pagination/pagination";
import BookCard from "./bookCard";
import HashLoader from "react-spinners/HashLoader";

export default function Shop() {
  const categoryId = useHistory();

  const getCategoryId = categoryId.location.search.substring(1);
  // console.log(getCategoryId);
  const [books, setbooks] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [loader, setloader] = useState(false);
const [pageNumber, setpageNumber] = useState(1)
  useEffect(() => {
    setloader(true);
    fetch(
      `${process.env.REACT_APP_URL}/publish/shop?category=${getCategoryId}&page=1&size=9`
    ).then((data) => {
      data.json().then((result) => {
        setloader(false);
        if (result.success) {
          setbooks(result.message);
        } else {
          setbooks([]);
        }
      });
    });
    fetch(
      `${process.env.REACT_APP_URL}/publish/get/count/shop/${getCategoryId}`
    ).then((response) =>
      response.json().then((data) => {
        if (data.success) {
          const newCount = Math.ceil(data.message / 10);
          settotalCount(newCount);
        } else {
          settotalCount(0);
        }
      })
    );
  }, []);
  
  const handleChange = (pageNumber) => {
    setloader(true);
    setpageNumber(pageNumber)
    fetch(
      `${process.env.REACT_APP_URL}/publish/shop?category=${getCategoryId}&page=${pageNumber}&size=9`
    ).then((data) => {
      data.json().then((result) => {
        setloader(false);
       
        if (result.success) {
          setbooks(result.message);
        } else {
          setbooks([]);
        }
      });
    });
  };

  return (
    <div>
      <Header />
      <section class="breadcrumb-section">
        <h2 class="sr-only">Site Breadcrumb</h2>
        <div class="container">
          <div class="breadcrumb-contents">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li class="breadcrumb-item active">
                  Category: {books.length ? books[0].category.name : ""}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      {loader ? (
        <div
          style={{ width: "100%", paddingTop: "50px", paddingBottom: "150px" }}
        >
          <div
            style={{
              width: "100%",

              paddingBottom: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <HashLoader color={"#6019CF"} size={100} />
          </div>
          <div
            style={{
              width: "100%",
              fontSize: "20px",
              fontWeight: "700",
              display: "flex",
              justifyContent: "center",
              color: "#6019CF",
            }}
          >
            Loading...
          </div>
        </div>
      ) : (
        <div className="container">
          <div class="row m-0" style={{ paddingBottom: "100px" }}>
          {books.map((data) => (
            <BookCard details={{ ...data }} />
          ))}
          <div
            style={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "center",
            }}
            className="col-12"
          >
            {books.length ? (
              <PaginationStore
                count={totalCount}
                handleChange1={handleChange}
                pageNumber={pageNumber}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
