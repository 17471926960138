import { combineReducers } from "redux";
import userReducer from "./userReducer";
import randomReducer from "./randomReducer";
import closeReaderReducer from "./closeReaderReducer";
import bookUrlReducer from "./bookUrlReducer";
const rootReducer = combineReducers({
  userReducer: userReducer,
  randomNumber: randomReducer,
  closeReader: closeReaderReducer,
  bookUrlReducer: bookUrlReducer,
});
export default rootReducer;
