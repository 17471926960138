import React from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import LoginForm from "./loginForm";
import RegForm from "./regForm";

export default function LoginRegister() {
  return (
    <div>
      <Header />
      <section class="breadcrumb-section">
        <h2 class="sr-only">Site Breadcrumb</h2>
        <div class="container">
          <div class="breadcrumb-contents">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li class="breadcrumb-item active">Login</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <main class="page-section inner-page-sec-padding-bottom">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-6 mb--30 mb-lg--0">
              <RegForm />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xs-12">
              <LoginForm />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
