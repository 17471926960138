import React, { useState } from "react";
import SimpleAlerts from "../../components/messageAlert/Alert";

export default function RegForm() {
  const [userDetails, setuserDetails] = useState({
    fullName: "",
    email: "",
    password: "",
    rpassword: "",
  });
  const [message, setmessage] = useState({
    status: "",
    message: "",
    loader: false,
  });
  const handleChange = (value, attr) => {
    setuserDetails({
      ...userDetails,
      [attr]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setmessage({
      ...message,
      loader: true,
    });
    if (userDetails.password !== userDetails.rpassword) {
      setmessage({
        status: "error",
        message: "The password you enter does not match.",
        loader: false,
      });
      setuserDetails({
        fullName: "",
        email: "",
        password: "",
        rpassword: "",
      });
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullName: userDetails.fullName,
          email: userDetails.email,
          password: userDetails.password,
        }),
      };
      fetch(`${process.env.REACT_APP_URL}/user/`, requestOptions).then(
        (res) => {
          res
            .json()
            .then((result) => {
              if (result.success) {
                setmessage({
                  status: "success",
                  message: result.message,
                  loader: false,
                });
                setuserDetails({
                  fullName: "",
                  email: "",
                  password: "",
                  rpassword: "",
                });
              } else {
                setmessage({
                  status: "error",
                  message: result.message,
                  loader: false,
                });
                setuserDetails({
                  fullName: "",
                  email: "",
                  password: "",
                  rpassword: "",
                });
              }
            })
            .catch((error) => {
              setmessage({
                status: "error",
                message: error.message,
                loader: false,
              });
              setuserDetails({
                fullName: "",
                email: "",
                password: "",
                rpassword: "",
              });
            });
        }
      );
    }
  };
  return (
    <div>
      <div class="login-form">
        <h4 class="login-title">New Customer</h4>
        <p>
          <span class="font-weight-bold">I am a new customer</span>
        </p>
        <div class="row">
          {message.message ? <SimpleAlerts details={{ ...message }} /> : ""}
          <div class="col-md-12 col-12 mb--15">
            <label for="email">Full Name</label>
            <input
              class="mb-0 form-control"
              type="text"
              id="name"
              placeholder="Enter your full name"
              onChange={(evt) => handleChange(evt.target.value, "fullName")}
              value={userDetails.fullName}
            />
          </div>
          <div class="col-12 mb--20">
            <label for="email">Email</label>
            <input
              class="mb-0 form-control"
              type="email"
              id="email"
              placeholder="Enter Your Email Address Here.."
              value={userDetails.email}
              onChange={(evt) => handleChange(evt.target.value, "email")}
            />
          </div>
          <div class="col-lg-6 mb--20">
            <label for="password">Password</label>
            <input
              class="mb-0 form-control"
              type="password"
              id="password"
              placeholder="Enter your password"
              onChange={(evt) => handleChange(evt.target.value, "password")}
              value={userDetails.password}
            />
          </div>
          <div class="col-lg-6 mb--20">
            <label for="password">Repeat Password</label>
            <input
              class="mb-0 form-control"
              type="password"
              id="repeat-password"
              placeholder="Repeat your password"
              onChange={(evt) => handleChange(evt.target.value, "rpassword")}
              value={userDetails.rpassword}
            />
          </div>
          <div class="col-md-12">
            {message.loader ? (
              <a href="" class="btn btn-outlined">
                Please wait...
              </a>
            ) : (
              <a href="" class="btn btn-outlined" onClick={handleSubmit}>
                Register
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
