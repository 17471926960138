import React, { useEffect, useState } from "react";
import ChildrenBooks from "../../components/childrenBooks/childrenBooks";
import ChildrenHome from "../../components/childrenMainHomePageBook/childrenHome";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import HomeFeature from "../../components/homeFeature/homeFeature";
import NewArrival from "../../components/newArriver/newArrival";
import PromoSection from "../../components/promoSection/promoSection";
import Promotion2 from "../../components/promotion2/promotion2";
import Promotion3 from "../../components/promotion2/promotion3";
import Slider from "../../components/slider/slider";
export default function Home() {
  const [allBooks, setallBooks] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/publish/all/books`).then((data) => {
      data.json().then((result) => {
        if (result.success) {
          console.log(result);
          setallBooks(result.message);
        } else {
          setallBooks([]);
        }
      });
    });
  }, []);
  console.log(allBooks);
  return (
    <div>
      <Header />
      <Slider />
      <HomeFeature />
      <PromoSection />

      <section class="section-margin">
        <div class="container">
          <div class="section-title section-title--bordered">
            <h2>Explor Our Store</h2>
          </div>

          <div
            class="product-slider sb-slick-slider slider-border-single-row"
            data-slick-setting='{
                        "autoplay": true,
                        "autoplaySpeed": 8000,
                        "slidesToShow": 5,
                        "dots":true
                    }'
            data-slick-responsive='[
                        {"breakpoint":1200, "settings": {"slidesToShow": 4} },
                        {"breakpoint":992, "settings": {"slidesToShow": 3} },
                        {"breakpoint":768, "settings": {"slidesToShow": 2} },
                        {"breakpoint":480, "settings": {"slidesToShow": 1} },
                        {"breakpoint":320, "settings": {"slidesToShow": 1} }
                    ]'
          >
            <ChildrenBooks details={{ ...allBooks[0] }} />
            <ChildrenBooks details={{ ...allBooks[1] }} />
            <ChildrenBooks details={{ ...allBooks[2] }} />
            <ChildrenBooks details={{ ...allBooks[3] }} />
            <ChildrenBooks details={{ ...allBooks[4] }} />
            <ChildrenBooks details={{ ...allBooks[5] }} />
            <ChildrenBooks details={{ ...allBooks[6] }} />
            <ChildrenBooks details={{ ...allBooks[7] }} />
            <ChildrenBooks details={{ ...allBooks[8] }} />
            <ChildrenBooks details={{ ...allBooks[9] }} />
            <ChildrenBooks details={{ ...allBooks[10] }} />
            <ChildrenBooks details={{ ...allBooks[11] }} />
          </div>
        </div>
      </section>
      <Promotion2 />
      <ChildrenHome />
      <Promotion3 />
      <NewArrival />
      <Footer />
    </div>
  );
}
