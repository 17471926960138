import React from "react";

export default function NewArrivalSlide({ newArrival }) {
  return (
    <div class="single-slide">
      <div class="product-card card-style-list">
        <div class="card-image">
          <img src={newArrival.image} alt="" style={{ height: "120px" }} />
        </div>
        <div class="product-card--body">
          <div class="product-header">
            <a href="" class="author">
              {newArrival.author === undefined
                ? ""
                : newArrival.author.length > 15
                ? "by " + newArrival.author.substring(0, 15) + "..."
                : "by " + newArrival.author}
            </a>
            <h3>
              <a href={"/details?productId=" + newArrival._id}>
                {newArrival.title === undefined
                  ? ""
                  : newArrival.title.length > 30
                  ? newArrival.title.substring(0, 30) + "..."
                  : newArrival.title}
              </a>
            </h3>
          </div>
          <div class="price-block">
            <span class="price">
              {Object.keys( newArrival).length ?newArrival.price===0||newArrival.price==="free"?"Free": "$" + newArrival.price : ""}
            </span>
            {/* <del class="price-old">£51.20</del>
              <span class="price-discount">20%</span> */}
          </div>
        </div>
      </div>
    </div>
  );
}
