import React, { useEffect, useState } from "react";
import moment from "moment";
import PaginationControlled from "../../components/pagination/pagination";
import ComfirmAlert from "../../components/comfirmationAlert/confirmationAlert";
import AudioPlayer from "../bookDetails/audioPlayer";

export default function OrderTable() {
  const userReducer = JSON.parse(
    localStorage.getItem("persist:reducerUserStore")
  );
  const parseUser = JSON.parse(userReducer.userReducer);
  const userCredentials = parseUser.credentials;
  const [order, setorder] = useState([]);
  const [totalCount, settotalCount] = useState(0);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_URL}/order/user/order/${userCredentials._id}?page=1&size=10`
    ).then((data) => {
      data.json().then((result) => {
        if (result.success) {
          setorder(result.message);
        } else {
          setorder([]);
        }
      });
    });
    fetch(
      `${process.env.REACT_APP_URL}/order/get/count/order/${userCredentials._id}`
    ).then((response) =>
      response.json().then((data) => {
        if (data.success) {
          const newCount = Math.ceil(data.message / 10);
          settotalCount(newCount);
        } else {
          settotalCount(0);
        }
      })
    );
  }, []);
  const handleChange = (pageNumber) => {
    fetch(
      `${process.env.REACT_APP_URL}/order/user/order/${userCredentials._id}?page=${pageNumber}&size=10`
    ).then((data) => {
      data.json().then((result) => {
        if (result.success) {
          setorder(result.message);
        } else {
          setorder([]);
        }
      });
    });
  };

  return (
    <div>
      {order.length ? (
        <div class="myaccount-table table-responsive text-center">
          <table class="table table-bordered">
            <thead class="thead-light">
              <tr>
                <th>Title</th>
                <th>Book Image</th>
                <th>Book Price</th>
                <th>Amount Paid</th>
                <th>Payment Type</th>
                <th>Transaction name</th>
                <th>Bank Name</th>
                {/* <th>Introductory Audio</th> */}
                <th>Status</th>
                <th>Payment Date</th>
                <th>Date Created</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {order.length
                ? order.map((list) => (
                    <tr>
                      <td>{list.bookId ? list.bookId.title : ""}</td>
                      <td>
                        <img
                          src={list.bookId ? list.bookId.image : ""}
                          alt="logo"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </td>
                      <td>{list.bookId ? "$" + list.bookId.price : ""}</td>
                      <td>{list.bookId ? "$" + list.amountPaid : ""}</td>
                      <td>{list.bookId ? list.paymentType : ""}</td>
                      <td>{list.bookId ? list.transactionName : ""}</td>
                      <td>{list.bookId ? list.bankName : ""}</td>
                      {/* <td>
                        {list.bookId ? (
                          list.bookId.audio ? (
                            <AudioPlayer shortAudio={list.bookId.audio} />
                          ) : (
                            "Not available"
                          )
                        ) : (
                          ""
                        )}
                      </td> */}
                      <td>{list ? list.status : ""}</td>
                      <td>{list ? list.transactionDate : ""}</td>
                      <td>
                        {list
                          ? moment(list.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )
                          : ""}
                      </td>
                      <td>
                        {list ? (
                          list.status === "pending" ? (
                            <ComfirmAlert orderId={list._id} />
                          ) : (
                            "Book available"
                          )
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <PaginationControlled
              count={totalCount}
              handleChange1={handleChange}
            />
          </div>
        </div>
      ) : (
        "No record found"
      )}
    </div>
  );
}
