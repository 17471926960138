import React, { useState } from "react";
import { useDispatch } from "react-redux";
import SimpleAlerts from "../../components/messageAlert/Alert";

export default function LoginForm() {
  const dispatch = useDispatch();
  const [userDetails, setuserDetails] = useState({
    email: "",
    password: "",
    code:"",
    rpassword:""
  });
  const [forgotPasswordState, setforgotPasswordState] = useState(false)
  const [SendVerificationMail, setSendVerificationMail] = useState(false)
  const [correctCode, setcorrectCode] = useState(false)
  const handleChange = (value, attr) => {
    setuserDetails({
      ...userDetails,
      [attr]: value,
    });
  };
  const [message, setmessage] = useState({
    status: "",
    message: "",
    loader: false,
  });
  const handleBacktoLogin=()=>{
    setforgotPasswordState(false)
    setSendVerificationMail(false)
    setcorrectCode(false);
  }
  const handleBacktoLoginFromCode=()=>{
    setforgotPasswordState(false)
    setSendVerificationMail(false)
    setcorrectCode(false);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setmessage({
      ...message,
      loader: true,
    });
   
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: userDetails.email,
        password: userDetails.password,
      }),
    };
    fetch(`${process.env.REACT_APP_URL}/user/login`, requestOptions).then(
      (res) => {
        res.json().then((result) => {
          if (result.success) {
            dispatch({ type: "user_details", data: result.message });
            window.location.href = "/";
          } else {
            setmessage({
              message: result.message,
              status: "error",
              loader: false,
            });
          }
        });
      }
    );
  };
  const handleForgotPassword=(e)=>{
    e.preventDefault();
    setforgotPasswordState(true)
    setSendVerificationMail(false)
    setcorrectCode(false);
  }
const handleSendMail=(e)=>{
  e.preventDefault()
  setmessage({
    message: "",
    status: "error",
    loader: true,
  });
  
  if (userDetails.email === "") {
  
    setmessage({
      message: "Please enter an email address",
      status: "error",
      loader: false,
    });
   
  } else {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email:userDetails.email
      }),
    };

    fetch(
      `${process.env.REACT_APP_URL}/user/checkPassword`,
      requestOptions
    ).then((res) => {
      res.json().then((result) => {
        if (result.success) {
          setSendVerificationMail(true)
          setforgotPasswordState(false)
          setcorrectCode(false);

          setmessage({
            message: "verification code has been sent to your email",
            status: "success",
            loader: false,
          });
         

          // return setmessageDetails({
          //   message:result.message,
          //   status:"success"
          // })
        } else {
          setSendVerificationMail(false)
          setmessage({
            message: result.message,
            status: "error",
            loader: false,
          });
        }
      });
    });
  }
}
const handleSubmitCode=(e)=>{
  e.preventDefault()
  console.log({email:userDetails.email,code:userDetails.code})
  setmessage({
    message: "",
    status: "error",
    loader: true,
  });
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
       code:userDetails.code
      }),
    };
    fetch(
      `${process.env.REACT_APP_URL}/user/checkCorrectConfirmationCode/${userDetails.email}`,
      requestOptions
    ).then((res) => {
      res.json().then((result) => {
        if (result.success) {
          setcorrectCode(true);
          setSendVerificationMail(false)
          setforgotPasswordState(false)
          setmessage({
            message: "You can proceed to change Password",
            status: "success",
            loader: false,
          });
        } else {
          setcorrectCode(false);
          
          setmessage({
            message: result.message,
            status: "error",
            loader: false,
          });
          
        }
      });
    });
}
const handleSubmitPassword=(e)=>{
  e.preventDefault()
  setmessage({
    message:"",
    status: "error",
    loader:true,
  });
  if (userDetails.password === "") {
    setmessage({
      message: "Please enter the required fields",
      status: "error",
      loader:false,
    });
    
  
  } else if (userDetails.password !== userDetails.rpassword) {
   
    setmessage({
      message: "The password you enter does not match",
      status: "error",
      loader:false,
    });
  } else if (userDetails.password.length < 6) {
   
    setmessage({
      message: "Please enter atleast 6 characters.",
      status: "error",
      loader:false,
    });
   
  } else {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password:userDetails.password,
        email:userDetails.email,
      }),
    };
    fetch(
      `${process.env.REACT_APP_URL}/user/reader/resetPassword`,
      requestOptions
    ).then((res) => {
      res.json().then((result) => {
        if (result.success) {
          setmessage({
            message: "Password Successfully change,proceed to login.",
            status: "success",
            loader:false,
          });
        
         
         
        } else {
          setmessage({
            message: result.message,
            status: "error",
            loader:false,
          });
        
        }
      });
    });
  }
}

  return (
    <div>
      <form>
        <div class="login-form">
          <h4 class="login-title">{correctCode?"Enter Your New Password.":forgotPasswordState?"Enter Your Email Address":SendVerificationMail?"Enter Varification Code":"Returning Customer"}</h4>
          <p>
            <span class="font-weight-bold">I am a returning customer</span>
          </p>
          {message.message ? <SimpleAlerts details={{ ...message }} /> : ""}
         
          { correctCode?
          <div class="row">
          <div class="col-md-12 col-12 mb--15">
            <label for="email">Enter your password here...</label>
            <input
              class="mb-0 form-control"
              type="password"
              id="login-password"
              placeholder="Enter your password"
              onChange={(evt) => handleChange(evt.target.value, "password")}
            />
          </div>
          <div class="col-12 mb--20">
            <label for="password">Confirm Password</label>
            <input
              class="mb-0 form-control"
              type="password"
              id="login-password"
              placeholder="Enter your password"
              onChange={(evt) => handleChange(evt.target.value, "rpassword")}
            />
          </div>
          <div class="col-md-12">
            {message.loader ? (
              <a href="" class="btn btn-outlined">
                Please wait...
              </a>
            ) : (
              <a href="" class="btn btn-outlined" onClick={handleSubmitPassword}>
               Submit Password
              </a>
            )}
            <a href="" class="btn btn-outlined" onClick={handleBacktoLoginFromCode} style={{marginLeft:"10px"}}>
               Login
              </a>
          </div>
        </div>
          :SendVerificationMail?
            <div class="row">
            <div class="col-md-12 col-12 mb--15">
              <label for="email">Enter verification code here...</label>
              <input
                class="mb-0 form-control"
                type="number"
                id="email1"
                placeholder="Enter varification code..."
                onChange={(evt) => handleChange(evt.target.value, "code")}
              />
            </div>
           
            <div class="col-md-12">
              {message.loader ? (
                <a href="" class="btn btn-outlined">
                  Please wait...
                </a>
              ) : (
               <div>
                  <a href="" class="btn btn-outlined" onClick={handleSubmitCode}>
                 Send code
                </a>
                <a href="" class="btn btn-outlined" onClick={handleBacktoLoginFromCode} style={{marginLeft:"10px"}}>
               Login
              </a>
               </div>
              )}
             
            </div>
          </div>:
           
            forgotPasswordState?
            <div class="row">
            <div class="col-md-12 col-12 mb--15">
              <label for="email">Enter your email address here...</label>
              <input
                class="mb-0 form-control"
                type="email"
                id="email1"
                placeholder="Enter you email address here..."
                onChange={(evt) => handleChange(evt.target.value, "email")}
              />
            </div>
           
            <div class="col-md-12">
              {message.loader ? (
                <a href="" class="btn btn-outlined">
                  Please wait...
                </a>
              ) : (
               <div>
                  <a href="" class="btn btn-outlined" onClick={handleSendMail}>
                 Send 
                </a>
                <a href="" class="btn btn-outlined" onClick={handleBacktoLogin} style={{marginLeft:"10px"}}>
               Login
              </a>
               </div>
              )}
             
            </div>
          </div>:<div class="row">
            <div class="col-md-12 col-12 mb--15">
              <label for="email">Enter your email address here...</label>
              <input
                class="mb-0 form-control"
                type="email"
                id="email1"
                placeholder="Enter you email address here..."
                onChange={(evt) => handleChange(evt.target.value, "email")}
              />
            </div>
            <div class="col-12 mb--20">
              <label for="password">Password</label>
              <input
                class="mb-0 form-control"
                type="password"
                id="login-password"
                placeholder="Enter your password"
                onChange={(evt) => handleChange(evt.target.value, "password")}
              />
            </div>
            <div class="col-md-12">
              {message.loader ? (
                <a href="" class="btn btn-outlined">
                  Please wait...
                </a>
              ) : (
                <a href="" class="btn btn-outlined" onClick={handleSubmit} style={{marginRight:"10px",marginBottom:"10px"}}>
                  Login
                </a>
              )}
              <a href="" class="btn btn-outlined" onClick={handleForgotPassword} style={{marginBottom:"10px"}} >
                  Forgot Password
                </a>
            </div>
          </div>
          }
        </div>
      </form>
    </div>
  );
}
