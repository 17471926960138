import React from "react";
import BasketAndView from "../../components/basketAndView/basketAndView";

export default function BookCard({ details }) {
  return (
    <div class="col-lg-4 col-sm-6">
      <div class="product-card">
        <div class="product-grid-content">
          <div class="product-header">
            <a class="author">by {details.author}</a>
            <h3>
              <a href={"/details?productId=" + details._id}>
                {details
                  ? details.title.length > 30
                    ? details.title.substring(0, 30) + "..."
                    : details.title
                  : ""}
              </a>
            </h3>
          </div>
          <div class="product-card--body">
            <div class="card-image">
              <img
                src={details.image}
                alt=""
                style={{ height: "250px", width: "200px" }}
              />
              <div class="hover-contents">
                <a
                  href={"/details?productId=" + details._id}
                  class="hover-image"
                >
                  {/* <img src={details.image} alt="" style={{ height: "250px" }} /> */}
                </a>
                <BasketAndView productId={details._id} />
              </div>
            </div>
            <div class="price-block">
              <span class="price">{Object.keys( details).length ?details.price===0||details.price==="free"?"Free": "$" + details.price : ""}</span>
              {/* <del class="price-old">£51.20</del>
              <span class="price-discount">20%</span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
