import React, { Component } from "react";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

class ComfirmAlert extends React.Component {
  deleteOrder = () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(
      `${process.env.REACT_APP_URL}/order/order/delete/${this.props.orderId}`,
      requestOptions
    ).then((res) => {
      res.json().then((result) => {
        if (result.success) {
          alert(result.message);
          window.location.href = "/my-account";
        } else {
          alert(result.message);
        }
      });
    });
  };
  submit = () => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteOrder(),
        },
        {
          label: "No",
          onClick: () => alert("Click No"),
        },
      ],
    });
  };

  render() {
    return (
      <a class="btn" onClick={this.submit} style={{ cursor: "pointer" }}>
        Delete
      </a>
    );
  }
}
export default ComfirmAlert;
