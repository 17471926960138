import React, { useEffect, useState } from "react";

export default function UserAddress() {
  const userReducer = JSON.parse(
    localStorage.getItem("persist:reducerUserStore")
  );
  const parseUser = JSON.parse(userReducer.userReducer);
  const userCredentials = parseUser.credentials;
  const [userProfile, setuserProfile] = useState({
    address: "",
    phoneNumber: "",
    displayName: "",
    fullName: "",
  });
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_URL}/user/profile/user/${userCredentials._id}`
    ).then((data) => {
      data.json().then((result) => {
        if (result.success) {
          setuserProfile({
            phoneNumber: result.message.phoneNumber
              ? result.message.phoneNumber
              : "Not Available",
            address: result.message.address
              ? result.message.address
              : "Not Available",
            displayName: result.message.displayName
              ? result.message.displayName
              : "Not Available",
            fullName: result.message.fullName
              ? result.message.fullName
              : "Not Available",
          });
        } else {
          setuserProfile(result.message);
        }
      });
    });
  }, []);

  return (
    <div>
      <address>
        <p>
          <strong>Hello,{userProfile.fullName}</strong>
        </p>
        <p>Address:{userProfile.address}</p>
        <p>Mobile:{userProfile.phoneNumber}</p>
      </address>
    </div>
  );
}
