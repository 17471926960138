const InitState = {
  credentials: null,
};
const userReducer = (state = InitState, action) => {
  switch (action.type) {
    case "user_details":
      return {
        credentials: action.data,
      };

    default:
      return state;
  }
};
export default userReducer;
