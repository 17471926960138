import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import SimpleCard from "./bookCard";
import PayWithRave from "./onlinePayment";
import SimpleTabs from "./paymentTab";

export default withRouter(function Payment({ history }) {
  const str = history.location.search;
  const bookId = str.substring(9);
  //   console.log(history.location);
  //   console.log(bookId);
  const userReducer = JSON.parse(
    localStorage.getItem("persist:reducerUserStore")
  );
  const parseUser = JSON.parse(userReducer.userReducer);
  const userCredentials = parseUser.credentials;

  const [product, setproduct] = useState("");
  // console.log(product);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/publish/books/${bookId}`).then(
      (data) => {
        data.json().then((result) => {
          if (result.success) {
            setproduct(result.message);
          } else {
            setproduct("");
          }
        });
      }
    );
  }, []);
  console.log(product);
  return (
    <div>
      <Header />
      <section class="breadcrumb-section">
        <h2 class="sr-only">Site Breadcrumb</h2>
        <div class="container">
          <div class="breadcrumb-contents">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li class="breadcrumb-item active">Payment</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <div class="checkout-quick-box">
        <p>
          <i class="far fa-sticky-note"></i>I don't want this book?{" "}
          <a href={"/details?productId=" + bookId} class="slide-trigger">
            Click here to go back
          </a>
        </p>
      </div>
      <div className="container" style={{ marginBottom: "150px" }}>
        <div className="row m-0">
          <div className="col">
            <SimpleCard details={{ ...product }} />
          </div>
          <div className="col-lg-8">
            <div
              style={{
                width: "100%",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              SELECT PAYMENT METHOD
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PayWithRave product={product} userDetails={userCredentials} />
              {/* <a
                class="btn btn-outlined--primary"
                style={{ cursor: "pointer", marginBottom: "20px" }}
              >
                Payment With Card
              </a> */}
            </div>
            <div
              style={{
                textAlign: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              ALTERNATE METHODS
            </div>
            <SimpleTabs details={{ ...product }} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
});
