import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import ChildrenBooks from "../../components/childrenBooks/childrenBooks";

export default withRouter(function RelatedProduct({ categoryId, history }) {
  const [relatedProduct, setrelatedProduct] = useState([]);
  const str = history.location.search;
  const bookId = str.substring(11);
  const getLoaderState = (data) => {
    console.log(data);
    if (data === undefined) {
      return setrelatedProduct([]);
    } else {
      fetch(`${process.env.REACT_APP_URL}/publish/related/book/${data}`).then(
        (data) => {
          data.json().then((result) => {
            if (result.success) {
              setrelatedProduct(result.message);
            } else {
              setrelatedProduct([]);
            }
          });
        }
      );
    }
  };
  useEffect(() => {
    getLoaderState(bookId);
  }, []);
  console.log(relatedProduct);
  return (
    <div>
      <section class="">
        <div class="container">
          <div class="section-title section-title--bordered">
            <h2>RELATED PRODUCTS</h2>
          </div>
          <div
            class="product-slider sb-slick-slider slider-border-single-row"
            data-slick-setting='{
                "autoplay": true,
                "autoplaySpeed": 8000,
                "slidesToShow": 4,
                "dots":true
            }'
            data-slick-responsive='[
                {"breakpoint":1200, "settings": {"slidesToShow": 4} },
                {"breakpoint":992, "settings": {"slidesToShow": 3} },
                {"breakpoint":768, "settings": {"slidesToShow": 2} },
                {"breakpoint":480, "settings": {"slidesToShow": 1} }
            ]'
          >
            <ChildrenBooks details={{ ...relatedProduct[0] }} />
            <ChildrenBooks details={{ ...relatedProduct[1] }} />
            <ChildrenBooks details={{ ...relatedProduct[2] }} />
            <ChildrenBooks details={{ ...relatedProduct[3] }} />
            <ChildrenBooks details={{ ...relatedProduct[4] }} />
            <ChildrenBooks details={{ ...relatedProduct[5] }} />
          </div>
        </div>
      </section>
    </div>
  );
});
