import React, { Component } from "react";
import { ReactReader } from "react-reader";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";

import { EpubViewer, ReactEpubViewer } from "react-epub-viewer";
const Epub = () => {
  const bookUrlReducer = useSelector((state) => state.bookUrlReducer);
  const bookurl = bookUrlReducer.bookUrl.bookUrl;
  const bookTitle = bookUrlReducer.bookUrl.bookTitle;
  const getHttps = bookurl.substring(4, 5);
const history=useHistory();

  const bookSecUrl =
    getHttps === "s"
      ? bookurl
      : bookurl.substring(0, 4) + "s" + bookurl.substring(4);

  const viewerRef = useRef(null);
  const dispatch = useDispatch()
  const handleGOback=()=>{
    
    dispatch({ type: "book_url", data: { bookUrl:"", bookTitle:"" } });
    window.location.reload()
  }
  return (
    <div>
      <div
        style={{ position: "absolute", zIndex: "1000", top: "2%", left: "90%" }}
      >
        {
          history.location.pathname==="/read"?<Link to="/my-account">
          {" "}
          <Button className="back__button">Close</Button>
        </Link>: <Button className="back__button" onClick={handleGOback}>Close</Button>
        }
        
      </div>
      <div style={{ position: "relative", height: "100vh" }}>
        <ReactReader
          url={bookurl ? bookSecUrl : ""}
          title={bookTitle}
          showToc={true}
        />
      </div>
    </div>

    //
  );
};
export default Epub;
