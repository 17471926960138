import React, { Component, useEffect, useState } from "react";
import moment from "moment";
// Import the Library
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";
const PayWithRave = ({ product, userDetails }) => {
  // const [details, setdetails] = useState({
  //   description: "",
  //   amount: 0,
  //   name: "",
  // });

  // useEffect(() => {
  //   setdetails({
  //     name: userDetails.fullName,
  //     description: `Payment for the book title:${product.title}`,
  //     amount:
  //   });
  // }, []);
  // console.log({ details });
  const handleToken = async (token) => {
    const response = await Axios.post(
      `${process.env.REACT_APP_URL}/publish/checkout/store/order/`,
      {
        description: `Payment for the book title:${product.title}`,
        amount: product.price * 100,
        name: userDetails.fullName,
        token,
      }
    );
    if (response.data.success) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amountPaid: product.price,
          bookId: product._id,
          buyerId: userDetails._id,
          transactionName: userDetails.fullName,
          bankName: "online payment/card",
          transactionDate: moment().format("MMMM Do YYYY, h:mm:ss a"),
          status: "pending",
          paymentType: "card/online-payment",
          userId: product.userId,
          publisherAmount: (product.price / 100) * 60,
        }),
      };
      fetch(`${process.env.REACT_APP_URL}/order/`, requestOptions).then(
        (res) => {
          res.json().then((result) => {
            if (result.success) {
              alert("Success! Payment successful");
              toast("Success! Payment successful", {
                type: "success",
              });
            } else {
              toast("Something went wrong", {
                type: "error",
              });
            }
          });
        }
      );
    } else {
      toast("Something went wrong", {
        type: "error",
      });
    }
  };
  return (
    <div>
      <StripeCheckout
        stripeKey="pk_test_onqdk46hblGVFXkqXst8jODS00KUSdlR0J"
        token={handleToken}
        amount={product.price * 100}
      />
    </div>
  );
};

export default PayWithRave;
