import React from "react";
import Rating from "@material-ui/lab/Rating";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export default function AvgRating({ averageRating }) {
  const [value, setValue] = React.useState(averageRating);

  return (
    <Rating
      name="read-only"
      value={averageRating}
      readOnly
      style={{ fontSize: "17px" }}
    />
  );
}
