import React from "react";
import BankTransferLogo from "../../images/money-transfer (1).png";
import BankTransferForm from "./bankTransferForm";
export default function BankTransfer({ details }) {
  return (
    <div>
      <img src={BankTransferLogo} alt="image-logo" />
      <span style={{ marginLeft: "10px", fontWeight: "700" }}>
        Bank Deposit/Transfer
      </span>
      <div style={{ marginBottom: "20px" }}>
        Pay into GT Bank, 0136442977, cawadi LTD then fill the form below Please
        include your email in the "narration" during the transfer from your bank
        or banking app.
      </div>
      <BankTransferForm details={{ ...details }} />
    </div>
  );
}
